import { useTranslation } from 'react-i18next'

interface FieldSelectProps {
  label: string
  id: number
}

export const useFieldTillageData = () => {
  const { t } = useTranslation()

  const fieldTillageOptions: FieldSelectProps[] = []

  for (let i = 1; i < 7; i++) {
    fieldTillageOptions.push({ label: t(`cropRotation.fieldTillageOptions.type${i}`), id: i })
  }

  return {
    fieldTillageOptions,
  }
}
