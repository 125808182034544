import React, {
  Dispatch, createContext, useState, SetStateAction,
} from 'react'
import { CompanyInfoProps } from '../components/CarbonEnrollment/CarbonEnrollment.types'
import { FuelItem } from '../components/CarbonEnrollment/FuelManagement/FuelManagement.types'
import { FertilizerItem } from '../components/CarbonEnrollment/FertilizerInvoiceManagement/FertilizerInvoiceManagement.types'
import { FuelInventoryItem } from '../components/CarbonEnrollment/InventoryOverlays/Fuel/Fuel.types'

interface CarbonEnrollmentDataContextProps {
  registeredCompanies: CompanyInfoProps[],
  setRegisteredCompanies: Dispatch<SetStateAction<CompanyInfoProps[]>>,
  activeCompany: CompanyInfoProps | null | undefined,
  setActiveCompany: Dispatch<SetStateAction<CompanyInfoProps | null| undefined>>,
  fuelData: FuelItem[],
  setFuelData: Dispatch<SetStateAction<FuelItem[]>>,
  fertilizerData: FertilizerItem[],
  setFertilizerData: Dispatch<SetStateAction<FertilizerItem[]>>
  fuelInventoryData: FuelInventoryItem[],
  setFuelInventoryData: Dispatch<SetStateAction<FuelInventoryItem[]>>,
}

export const CarbonEnrollmentContext = createContext<CarbonEnrollmentDataContextProps>({
  registeredCompanies: [],
  setRegisteredCompanies: () => {},
  activeCompany: undefined,
  setActiveCompany: () => {},
  fuelData: [],
  setFuelData: () => {},
  fertilizerData: [],
  setFertilizerData: () => {},
  fuelInventoryData: [],
  setFuelInventoryData: () => {},
})

export const CarbonEnrollmentProvider = ({ children }: {children: React.ReactNode}) => {
  const [registeredCompanies, setRegisteredCompanies] = useState<CompanyInfoProps[]>([])
  const [activeCompany, setActiveCompany] = useState<CompanyInfoProps | null | undefined>(undefined)
  const [fuelData, setFuelData] = useState<FuelItem[]>([])
  const [fertilizerData, setFertilizerData] = useState<FertilizerItem[]>([])
  const [fuelInventoryData, setFuelInventoryData] = useState<FuelInventoryItem[]>([])

  const memoizedValue = React.useMemo(() => ({
    registeredCompanies,
    setRegisteredCompanies,
    activeCompany,
    setActiveCompany,
    fuelData,
    setFuelData,
    fertilizerData,
    setFertilizerData,
    fuelInventoryData,
    setFuelInventoryData,
  }), [
    registeredCompanies,
    setRegisteredCompanies,
    activeCompany,
    setActiveCompany,
    fuelData,
    setFuelData,
    fertilizerData,
    setFertilizerData,
    fuelInventoryData,
    setFuelInventoryData,
  ])

  return (
    <CarbonEnrollmentContext.Provider value={memoizedValue}>
      {children}
    </CarbonEnrollmentContext.Provider>
  )
}
