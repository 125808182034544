import React, {
  Dispatch, SetStateAction, useContext, useEffect, useRef, useState,
} from 'react'
import * as ls from 'local-storage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/free-solid-svg-icons'
import { API } from '../../../providers/API'
import { CarbonEnrollmentContext } from '../../../providers/CarbonEnrollmentProvider'
import { CompanyInfoProps } from '../CarbonEnrollment.types'
import { CompanyInfoForm } from '../CompanyInfoForm/CompanyInfoForm'
import { OverlayContainer, SimpleBox } from '../../../App.styles'
import { CompanyChoiceButton } from '../CarbonEnrollment.styles'

export const HeaderCompanyInfo = (
  { setMissingCompanyInfo }: {setMissingCompanyInfo: Dispatch<SetStateAction<boolean | undefined>>},
) => {
  const {
    setRegisteredCompanies,
    activeCompany,
    setActiveCompany,
  } = useContext(CarbonEnrollmentContext)

  const [showCompanyPopup, setShowCompanyPopup] = useState<boolean>(false)

  const popupContentRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    API.get('/listUserCompanies', { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setRegisteredCompanies(response.data)
      const activeCompanyId = response.data.length > 0 ? response.data[0].id : null
      setActiveCompany(response.data.find(
        (company: CompanyInfoProps) => company.id === activeCompanyId,
      ))
      setMissingCompanyInfo(response.data.length === 0)
    })
  }, [])

  const handleShowCompanyPopup = ({ event, overlay }: {event: any, overlay: boolean}) => {
    if (overlay && (!popupContentRef.current || popupContentRef.current.contains(event.target))) {
      return
    }

    setShowCompanyPopup((prev: boolean) => !prev)
  }

  const listenForEscapeKey = (e: any) => {
    if (e.key === 'Escape') {
      setShowCompanyPopup(false)
    }
  }

  useEffect(() => {
    if (showCompanyPopup) {
      window.addEventListener('keyup', listenForEscapeKey)
    }

    return () => window.removeEventListener('keyup', listenForEscapeKey)
  }, [showCompanyPopup])

  if (!activeCompany) {
    return null
  }

  return (
    <>
      <CompanyChoiceButton type="button" onClick={(event: any) => handleShowCompanyPopup({ event, overlay: false })}>{activeCompany.name} <FontAwesomeIcon icon={faPencil} size="sm" /></CompanyChoiceButton>
      {showCompanyPopup && (
      <OverlayContainer onClick={(event: any) => handleShowCompanyPopup({ event, overlay: true })}>
        <SimpleBox inModal ref={popupContentRef}>
          <CompanyInfoForm setShowCompanyPopup={setShowCompanyPopup} />
        </SimpleBox>
      </OverlayContainer>
      )}
    </>
  )
}
