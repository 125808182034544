export const useEnrollmentSeasonsData = () => {
  const seasons = ['spring', 'summer', 'autumn', 'winter']
  const seasonRanges = {
    spring: ['03-01', '05-31'],
    summer: ['06-01', '08-31'],
    autumn: ['09-01', '11-30'],
    winter: ['12-01', '02-28'],
  }
  const seasonOptions = []

  for (let i = 0; i < 7; i++) {
    const year = new Date().getFullYear() - i
    for (let t = 0; t < 4; t++) {
      seasonOptions.push(`${year} ${seasons[t]}`)
    }
  }

  const seasonFormOptions = seasonOptions.map((option, index) => ({
    label: option, id: index, year: option.split(' ')[0], season: option.split(' ')[1],
  }))

  return {
    seasonFormOptions,
    seasonRanges,
  }
}
