import styled, { css } from 'styled-components'
import { theme } from '../../theme/theme'

export const MainContent = styled.div`
    flex-grow: 1;
`

export const LayoutContainer = styled.div<{isLoggedIn: boolean}>(
  ({ isLoggedIn }) => css`
    display: flex;
    justify-content: space-between;

    ${MainContent} {
        padding: ${isLoggedIn ? `0 ${theme.space.lg} ${theme.space.lg} 320px` : '0'};

        ${theme.mediaQueries.mdMax} {
            padding: 0 ${theme.space.md} ${theme.space.md};
        }
    }
`,
)
