import styled from 'styled-components'
import { theme } from '../../theme/theme'

export const InventoryButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: ${theme.space.md};
    margin-bottom: ${theme.space.md};
`

export const InventoryButton = styled.button`
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    height: 100px;
    background: ${theme.colors.blueBGUI};
    color: white;
    border: none;
    padding: ${theme.space.md} 0 ${theme.space.md} ${theme.space.lg};
    border-radius: 10px;
    cursor: pointer;
    transition: background 0.3s;
    font-size: ${theme.fontSizes.xl};
    text-align: left;

    &:hover {
        background-color: ${theme.colors.blueUI};
    }

    & > * {
        margin-right: ${theme.space.lg};
    }

    ${theme.mediaQueries.mdMax} {
        flex-direction: column;
        text-align: center;
        padding-left: ${theme.space.md};
        padding-right: ${theme.space.md};
        height: auto;
        font-size: ${theme.fontSizes.md};

        & > * {
            margin: 0 0 ${theme.space.sm} 0;
        }
    }
`

export const CompanyFormSeparator = styled.div`
    text-align: center;
    margin: 0 0 ${theme.space.md} 0;
`

export const CompanyChoiceButton = styled.button`
    background: none;
    color: white;
    border: none;
    padding: 0;
    border-radius: 10px;
    cursor: pointer;
    font-size: ${theme.fontSizes.md};

    &:hover, &:focus {
        text-decoration: underline;
    }   
`
