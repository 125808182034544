import styled, { css } from 'styled-components'
import { FieldDetailsAddButton } from '../FieldDetails/FIeldDetails.styles'

export const DataTableContainer = styled.div(
  ({ theme }) => css`
    padding: ${theme.space.md};
    overflow: auto;
    background: ${theme.colors.blueBGUI};
    border-radius: 10px;


    .MuiTableCell-root {
      color: #FFF;
      font-size: 1rem;
      border: none;
      border-bottom: 1px dotted ${theme.colors.blueUI};
    }
`,
)

export const ResponsiveTableWrap = styled.div(
  ({ theme }) => css`
    position: relative;
    width: 100%;
    display: table;
    table-layout: fixed;
    padding-bottom: ${theme.space.lg};
`,
)

export const DataTableFormFieldsGrid = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: ${theme.space.md};
    margin-bottom: ${theme.space.md};

    ${theme.mediaQueries.md} {
      grid-template-columns: 1fr 1fr;
    }

    & > *, & .MuiFormControl-root {
    margin: 0 !important;
    }
`,
)

export const AddFormHeading = styled.h2(
  ({ theme }) => css`
    margin: 0 0 ${theme.space.lg} 0;
`,
)

export const DataTableEditFormHolder = styled.div<{isAddFormOpen: boolean}>(
  ({ theme, isAddFormOpen }) => css`
    position: relative;
    padding: ${isAddFormOpen ? theme.space.md : 0};
    background: ${theme.colors.blueBGUI};
    border-radius: 10px;

    ${FieldDetailsAddButton} {
      bottom: auto;
      top: -56px;
    }
`,
)
