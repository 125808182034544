import React, {
  useEffect, useState, useContext, Dispatch, SetStateAction,
} from 'react'
import { useTranslation } from 'react-i18next'
import * as ls from 'local-storage'
import Button from '@mui/material/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableSortLabel from '@mui/material/TableSortLabel'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { CropRotationResponse, CropRotationItem } from './CropRotation.types'
import { API } from '../../providers/API'
import { Loading } from '../Loading/Loading'
import {
  DataTableContainer, DataTableEditFormHolder, ResponsiveTableWrap,
} from './CropRotation.styles'
import { CropRotationRow } from './CropRotationRow'
import {
  CashCropOptionsTypes, CashCropTypes, useCashCropsData, updateCropLabels,
} from '../../utils/useCashCropsData'
import i18n from '../../i18n'
import { CropRotationEditForm } from './CropRotationEditForm'
import { GlobalStateContext } from '../../providers/GlobalStateProvider'
import { InfoNotification } from '../SuccessNotification/SuccessNotification.styles'
import { theme } from '../../theme/theme'
import { CarbonEnrollmentCropRotationItem } from '../CarbonEnrollment/CarbonEnrollment.types'
import { OrderTypes, getComparator } from '../../utils/cropRotationSortingFunctions'

interface CropRotationProps {
  fieldId?: number
  handleCropRotationData?: Dispatch<SetStateAction<CropRotationResponse | undefined>>
  isEnrollment?: boolean
  companyId?: number
}

type CropRotationDataProps =
  CropRotationResponse | CarbonEnrollmentCropRotationItem[] | undefined

type PreviousCropsDataProps = CropRotationItem[] | CarbonEnrollmentCropRotationItem[]

export const CropRotation = (
  {
    fieldId, handleCropRotationData, isEnrollment = false, companyId,
  }: CropRotationProps,
) => {
  const { t } = useTranslation()
  const { userInfo } = useContext(GlobalStateContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isAddPrevFormOpen, setIsAddPrevFormOpen] = useState<boolean>(false)
  const [isAddNextFormOpen, setIsAddNextFormOpen] = useState<boolean>(false)
  const [cropRotationData, setCropRotationData] = useState<CropRotationDataProps>()
  const [previousCrops, setPreviousCrops] = useState<PreviousCropsDataProps>([])
  const [cropDropdownOptions, setCropDropdownOptions] = useState<CashCropOptionsTypes[]>([])
  const [orderBy, setOrderBy] = React.useState<keyof CropRotationItem | keyof CarbonEnrollmentCropRotationItem>('name')
  const [order, setOrder] = React.useState<OrderTypes>('asc')
  // eslint-disable-next-line no-undef
  const [availableCrops, setAvailableCrops] = useState<CashCropTypes[]>([])
  const {
    cashCrops,
    cashCropSelectOptions,
  } = useCashCropsData()

  const fetchUrl = isEnrollment ? `/carbonCropRotationFields/${companyId}` : `/cropRotationField/${fieldId}`
  const colSpan = isEnrollment ? 7 : 6

  const loadCropRotationData = () => {
    setIsLoading(true)
    API.get(fetchUrl, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then(({ data }: {data: CropRotationResponse}) => {
      setCropRotationData(data)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    loadCropRotationData()
  }, [companyId])

  useEffect(() => {
    if (cropRotationData && handleCropRotationData) {
      handleCropRotationData(cropRotationData as CropRotationResponse)
    }
    if (isEnrollment && cropRotationData) {
      const data = cropRotationData as CarbonEnrollmentCropRotationItem[]
      setPreviousCrops(data || [])
    } else {
      const data = cropRotationData as CropRotationResponse
      setPreviousCrops(data?.previousCrops || [])
    }
  }, [cropRotationData])

  useEffect(() => {
    if (cashCrops.length === 0 || cashCropSelectOptions.length === 0) return
    setAvailableCrops(cashCrops)
    setCropDropdownOptions(cashCropSelectOptions)
  }, [cashCrops, cashCropSelectOptions])

  const handleReplaceRowData = (
    { newData, cropTypes } : {newData: CropRotationItem, cropTypes: 'nextCrop' | 'previousCrops' | 'carbonEnrollment'},
  ) => {
    let updatedData = {}
    const data = cropRotationData as CropRotationResponse
    if (cropTypes !== 'carbonEnrollment' && Array.isArray(data[cropTypes])) {
      // @ts-ignore
      updatedData = cropRotationData![cropTypes].map((item: CropRotationItem) => {
        if (item.id === newData.id) {
          return newData
        }
        return item
      })
    } else if (cropTypes === 'nextCrop') {
      updatedData = newData
    } else if (cropTypes === 'carbonEnrollment' && Array.isArray(cropRotationData)) {
      updatedData = cropRotationData!.map((item: CarbonEnrollmentCropRotationItem) => {
        if (item.id === newData.id) {
          return newData
        }
        return item
      })
    }

    if (cropTypes === 'carbonEnrollment') {
      setCropRotationData(updatedData as CarbonEnrollmentCropRotationItem[])
    } else {
      setCropRotationData((prev) => ({ ...prev!, [cropTypes]: updatedData }))
    }
  }

  const handleAddPrevItemFormDisplayToggle = () => {
    setIsAddPrevFormOpen((prev) => !prev)
  }

  const handleAddNextItemFormDisplayToggle = () => {
    setIsAddNextFormOpen((prev) => !prev)
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof CropRotationItem | keyof CarbonEnrollmentCropRotationItem,
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const createSortHandler = (
    property: keyof CropRotationItem | keyof CarbonEnrollmentCropRotationItem,
  ) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property)
  }

  const sortedRowsData = React.useMemo(
    () => [...previousCrops]
      // @ts-ignore
      .sort(getComparator(order, orderBy)),
    [previousCrops, order, orderBy],
  )

  useEffect(() => {
    setCropDropdownOptions(updateCropLabels(availableCrops))
  }, [i18n.language])

  if (cropDropdownOptions.length === 0 || isLoading) {
    return <Loading />
  }

  return (
    <>
      {isEnrollment && (
        <CropRotationEditForm
          isEnrollment
          cropType="prevCrop"
          rowData={null}
          cashCropOptions={cashCropSelectOptions}
          reloadCropRotationData={loadCropRotationData}
        />
      )}
      <h2>{t('cropRotation.cropRotation')}</h2>
      <DataTableContainer>
        <ResponsiveTableWrap>
          <Table sx={{ minWidth: 650, borderCollapse: 'collapse' }} aria-label={t('cropRotation.cropRotation')}>
            <TableHead>
              <TableRow>
                {isEnrollment && (
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'name'}
                    direction={orderBy === 'name' ? order : 'asc'}
                    onClick={createSortHandler('name')}
                  >
                    {t('general.field')}
                    {orderBy === 'name' ? (
                      <span className="visuallyHidden">
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                )}
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'cropId'}
                    direction={orderBy === 'cropId' ? order : 'asc'}
                    onClick={createSortHandler('cropId')}
                  >
                    {t('cropRotation.cropName')}
                    {orderBy === 'cropId' ? (
                      <span className="visuallyHidden">
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">{t('cropRotation.year')}</TableCell>
                <TableCell align="center">
                  <TableSortLabel
                    active={orderBy === 'seedingDate'}
                    direction={orderBy === 'seedingDate' ? order : 'asc'}
                    onClick={createSortHandler('seedingDate')}
                  >
                    {t('cropRotation.seedingDate')}
                    {orderBy === 'seedingDate' ? (
                      <span className="visuallyHidden">
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">
                  <TableSortLabel
                    active={orderBy === 'harvestDate'}
                    direction={orderBy === 'harvestDate' ? order : 'asc'}
                    onClick={createSortHandler('harvestDate')}
                  >
                    {t('cropRotation.harvestDate')}
                    {orderBy === 'harvestDate' ? (
                      <span className="visuallyHidden">
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">
                  <TableSortLabel
                    active={orderBy === 'tillage'}
                    direction={orderBy === 'tillage' ? order : 'asc'}
                    onClick={createSortHandler('tillage')}
                  >
                    {t('cropRotation.tillage')}
                    {orderBy === 'tillage' ? (
                      <span className="visuallyHidden">
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEnrollment && (
              <>
                <TableRow>
                  <TableCell colSpan={colSpan} sx={{ backgroundColor: theme.colors.blueBGUIMedium, paddingY: theme.space.sm }}>{t('cropRotation.nextCrop')}</TableCell>
                </TableRow>

                {cropRotationData && (cropRotationData as CropRotationResponse).nextCrop ? (
                  <CropRotationRow
                    rowData={(cropRotationData as CropRotationResponse).nextCrop}
                    key="nextcrop-row-key"
                    cashCropOptions={cropDropdownOptions}
                    replaceRowData={handleReplaceRowData}
                    reloadCropRotationData={loadCropRotationData}
                  />
                ) : (
                  <>
                    <TableRow>
                      <TableCell colSpan={colSpan} sx={{ padding: 0, border: 'none !important' }}>
                        <InfoNotification marginBottom="0px">{t('cropRotation.noNextCropData')}</InfoNotification>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={colSpan} sx={{ padding: 0, border: 'none !important' }}>
                        <DataTableEditFormHolder isAddFormOpen={isAddNextFormOpen}>
                          {!isAddNextFormOpen && (
                          <Button
                            variant="contained"
                            type="button"
                            color="primary"
                            endIcon={<FontAwesomeIcon icon={faPlus} />}
                            onClick={handleAddNextItemFormDisplayToggle}
                            style={{ marginTop: theme.space.md, marginBottom: theme.space.md }}
                          >
                            {t('cropRotation.addNextCrop')}
                          </Button>
                          )}
                          {isAddNextFormOpen && (
                          <CropRotationEditForm
                            rowData={null}
                            cropType="nextCrop"
                            cashCropOptions={cropDropdownOptions}
                            reloadCropRotationData={loadCropRotationData}
                            fieldId={fieldId}
                            userId={userInfo.id}
                            toggleAddForm={handleAddNextItemFormDisplayToggle}
                          />
                          )}
                        </DataTableEditFormHolder>
                      </TableCell>
                    </TableRow>
                  </>
                )}
                <TableRow>
                  <TableCell colSpan={colSpan} sx={{ backgroundColor: theme.colors.blueBGUIMedium, paddingY: theme.space.sm }}>{t('cropRotation.previousCrops')}</TableCell>
                </TableRow>
              </>
              )}
              {sortedRowsData.length > 0 ? (
                sortedRowsData.map((row: CropRotationItem | CarbonEnrollmentCropRotationItem) => (
                  <CropRotationRow
                    isEnrollment={isEnrollment}
                    rowData={row}
                    key={`row-key-${row.id}`}
                    cashCropOptions={cropDropdownOptions}
                    replaceRowData={handleReplaceRowData}
                    reloadCropRotationData={loadCropRotationData}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={colSpan} sx={{ padding: 0, border: 'none !important' }}>
                    <InfoNotification marginBottom="0px">{t('cropRotation.noPrevCropData')}</InfoNotification>
                  </TableCell>
                </TableRow>

              )}
              {!isEnrollment && (
              <TableRow>
                <TableCell colSpan={colSpan} sx={{ padding: 0, border: 'none !important' }}>
                  <DataTableEditFormHolder isAddFormOpen={isAddPrevFormOpen}>
                    {!isAddPrevFormOpen && (
                    <Button
                      variant="contained"
                      type="button"
                      color="primary"
                      endIcon={<FontAwesomeIcon icon={faPlus} />}
                      onClick={handleAddPrevItemFormDisplayToggle}
                      style={{ marginTop: theme.space.md, marginBottom: theme.space.md }}
                    >
                      {t('cropRotation.addPrevCrop')}
                    </Button>
                    )}
                    {isAddPrevFormOpen && (
                    <CropRotationEditForm
                      rowData={null}
                      cropType="prevCrop"
                      cashCropOptions={cropDropdownOptions}
                      reloadCropRotationData={loadCropRotationData}
                      fieldId={fieldId}
                      userId={userInfo.id}
                      toggleAddForm={handleAddPrevItemFormDisplayToggle}
                    />
                    )}
                  </DataTableEditFormHolder>
                </TableCell>
              </TableRow>
              )}
            </TableBody>
          </Table>
        </ResponsiveTableWrap>
      </DataTableContainer>
    </>
  )
}
