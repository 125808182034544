import React, { useState } from 'react'
import * as ls from 'local-storage'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Collapse from '@mui/material/Collapse'
import { Button } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faCheck } from '@fortawesome/free-solid-svg-icons'
import { API } from '../../../../providers/API'
import { FuelInventoryItem } from './Fuel.types'
import { theme } from '../../../../theme/theme'
import { FuelInventoryDataEntryForm } from './FuelDataEntryForm'

export const FuelInventoryRow = ({
  rowData, replaceRowData,
} : {
  rowData: FuelInventoryItem,
  replaceRowData:({ newData } : {newData: FuelInventoryItem}) => void
  }) => {
  const [isConfirming, setIsConfirming] = useState<boolean>(false)
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false)

  const handleClickConfirmFuelUsage = () => {
    setIsConfirming(true)
    const formDataToSave = {
      ...rowData,
      confirmation: rowData.confirmation === 1 ? 0 : 1,
    }

    API.post('/fuelUsageConfirm', formDataToSave, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then(({ data }: {data: FuelInventoryItem}) => {
      setIsConfirming(false)
      replaceRowData({ newData: data })
    }).catch(() => {
      setIsConfirming(false)
    })
  }

  return (
    <>
      <TableRow
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell>{rowData.year}</TableCell>
        <TableCell align="center">{rowData.petrolInventory}</TableCell>
        <TableCell align="center">{rowData.petrolUsed}</TableCell>
        <TableCell align="center">{rowData.dieselInventory}</TableCell>
        <TableCell align="center">{rowData.dieselUsed}</TableCell>
        <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
          <Button
            sx={{ minWidth: 'auto' }}
            variant="contained"
            type="button"
            color="primary"
            onClick={() => setIsEditOpen((prev) => !prev)}
          ><FontAwesomeIcon icon={faEdit} size="lg" />
          </Button>
          <LoadingButton
            sx={{ minWidth: 'auto', marginLeft: theme.space.sm }}
            variant="contained"
            type="button"
            color={rowData.confirmation === 1 ? 'success' : 'error'}
            loadingPosition="start"
            loading={isConfirming}
            onClick={handleClickConfirmFuelUsage}
          ><FontAwesomeIcon icon={faCheck} size="lg" />
          </LoadingButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, borderWidth: isEditOpen ? 1 : 0 }}
          colSpan={6}
        >
          <Collapse in={isEditOpen} timeout="auto" unmountOnExit sx={{ padding: `${theme.space.md} 0;` }}>
            <FuelInventoryDataEntryForm
              rowData={rowData}
              replaceRowData={replaceRowData}
              toggleAddForm={() => setIsEditOpen(false)}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
