import React, { useContext } from 'react'
import { Route, Switch } from 'react-router-dom'
import { GlobalStateContext } from './providers/GlobalStateProvider'
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute'

import { LoginPage } from './pages/Login'
import { ReferencePage } from './pages/ReferencePage'
import { RegisterPage } from './pages/Register'
// import { WelcomePage } from './pages/Welcome'
import { FieldsPage } from './pages/Fields'
import { FieldDetails } from './pages/FieldDetails'
import { CreateFieldPage } from './pages/CreateField'
import { MixesPage } from './pages/Mixes'
import { CreateMixPage } from './pages/CreateMix'
import { MixDetails } from './pages/MixDetails'
import { WeatherListing } from './pages/WeatherListing'
import { WeatherField } from './pages/WeatherField'
import { ForgotPassword } from './pages/ForgotPassword'
import { ResetPassword } from './pages/ResetPassword'
import { Account } from './pages/Account'
import { AuthFailureNotification } from './components/AuthFailureNotification/AuthFailureNotification'
import { TokenPackages } from './pages/TokenPackages'
import { SoilAnalysisListing } from './pages/SoilAnalysisListing'
import { SoilAnalysisSummary } from './pages/SoilAnalysisSummary'
import { SoilAnalysisDetails } from './pages/SoilAnalysisDetails'
import { AddAnalysisToField } from './pages/AddAnalysisToField'
import { NdviField } from './pages/NDVIField'
import { NdviFieldsListing } from './pages/NdviFieldsListing'
import { NdviFieldsCompare } from './pages/NdviFieldsCompare'
import { SatSoilHistoryListing } from './pages/SatSoilHistoryListing'
import { SatSoilHistory } from './pages/SatSoilHistoryDetails'
import { FieldZones } from './pages/FieldZones'
import { FieldZoneDetails } from './pages/FieldZoneDetails'
import { FieldsDataOverview } from './pages/FieldsDataOverview'
import { CarbonEnrollment } from './pages/CarbonEnrollment'

export const Routes = () => {
  const {
    userInfo, authError,
  } = useContext(GlobalStateContext)

  return (
    <>
      {authError === true && (
      <AuthFailureNotification />
      )}

      <Switch>
        <Route path="/" exact component={userInfo === null || Object.keys(userInfo).length === 0 ? LoginPage : FieldsPage} />
        <Route path="/welcome" exact component={LoginPage} />
        <Route path="/login" exact component={LoginPage} />
        <Route
          path="/register"
          exact
          component={RegisterPage}
        />
        <Route
          path="/resetPwd"
          exact
          component={ResetPassword}
        />
        <Route
          path="/forgotPassword"
          exact
          component={ForgotPassword}
        />
        <ProtectedRoute
          path="/referencePage"
          accessLevel={1}
          component={ReferencePage}
        />
        <ProtectedRoute
          path="/carbonEnrollment"
          accessLevel={1}
          component={CarbonEnrollment}
        />
        <ProtectedRoute
          path="/account/:section"
          accessLevel={1}
          component={Account}
        />
        <ProtectedRoute
          path="/fields"
          accessLevel={1}
          component={FieldsPage}
        />
        <ProtectedRoute
          path="/fieldsData"
          accessLevel={1}
          component={FieldsDataOverview}
        />
        <ProtectedRoute
          path="/fieldDetails/:fieldId"
          accessLevel={1}
          component={FieldDetails}
        />
        <ProtectedRoute
          path="/addAnalysisToField/:fieldId"
          accessLevel={1}
          component={AddAnalysisToField}
        />
        <ProtectedRoute
          path="/createField"
          accessLevel={1}
          component={CreateFieldPage}
        />
        <ProtectedRoute
          path="/mixes"
          accessLevel={1}
          component={MixesPage}
        />
        <ProtectedRoute
          path="/mixDetails/:mixId"
          accessLevel={1}
          component={MixDetails}
        />
        <ProtectedRoute
          path="/createMix"
          accessLevel={1}
          component={CreateMixPage}
        />
        <ProtectedRoute
          path="/weatherListing"
          accessLevel={1}
          component={WeatherListing}
        />
        <ProtectedRoute
          path="/WeatherField/:id"
          accessLevel={1}
          component={WeatherField}
        />
        <ProtectedRoute
          path="/tokenPackages"
          accessLevel={1}
          component={TokenPackages}
        />
        <ProtectedRoute
          path="/soilAnalysisListing"
          accessLevel={1}
          component={SoilAnalysisListing}
        />
        <ProtectedRoute
          path="/soilAnalysisField/:id"
          accessLevel={1}
          component={SoilAnalysisSummary}
        />
        <ProtectedRoute
          path="/soilAnalysisDetails/:soilAnalysisId"
          accessLevel={1}
          component={SoilAnalysisDetails}
        />
        <ProtectedRoute
          path="/ndviFieldsListing"
          accessLevel={1}
          component={NdviFieldsListing}
        />
        <ProtectedRoute
          path="/ndviField/:fieldId"
          accessLevel={1}
          component={NdviField}
        />
        <ProtectedRoute
          path="/ndviFieldsCompare"
          accessLevel={1}
          component={NdviFieldsCompare}
        />
        <ProtectedRoute
          path="/sateliteSoilHistory"
          accessLevel={1}
          component={SatSoilHistoryListing}
        />
        <ProtectedRoute
          path="/sateliteSoilHistoryDetails/:fieldId"
          accessLevel={1}
          component={SatSoilHistory}
        />
        <ProtectedRoute
          path="/fieldZones"
          accessLevel={1}
          component={FieldZones}
        />
        <ProtectedRoute
          path="/fieldZoneDetails/:zoneId"
          accessLevel={1}
          component={FieldZoneDetails}
        />
      </Switch>
    </>
  )
}
