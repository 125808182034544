import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { DataTableContainer, ResponsiveTableWrap } from '../../CropRotation/CropRotation.styles'
import { FertilizerItem } from './FertilizerInvoiceManagement.types'
import { InfoNotification } from '../../SuccessNotification/SuccessNotification.styles'
import { FertilizerDataRow } from './FertilizerInvoiceDataRow'

export const FertilizerDataListing = (
  {
    fertilizerData, setFertilizerData, reloadData,
  } : {
    fertilizerData: FertilizerItem[],
    setFertilizerData: Dispatch<SetStateAction<FertilizerItem[]>>
    reloadData: () => void
    },
) => {
  const { t } = useTranslation()

  const handleReplaceRowData = ({ newData } : {newData: FertilizerItem}) => {
    const updatedData = fertilizerData.map((item: FertilizerItem) => {
      if (item.id === newData.id) {
        return newData
      }
      return item
    })

    setFertilizerData(updatedData)
  }

  return (
    <DataTableContainer>
      <ResponsiveTableWrap>
        <Table sx={{ minWidth: 650, borderCollapse: 'collapse' }} aria-label={t('fertilizerInvoiceManagement.title')}>
          <TableHead>
            <TableRow>
              <TableCell>{t('general.invoice')}</TableCell>
              <TableCell align="center">{t('general.year')}</TableCell>
              <TableCell align="center">{t('general.date')}</TableCell>
              <TableCell align="center">{t('fertilizerInvoiceManagement.fertilizerType')}</TableCell>
              <TableCell align="center">{t('fertilizerInvoiceManagement.percentage')}</TableCell>
              <TableCell align="center">{t('fertilizerInvoiceManagement.quantity')}</TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {fertilizerData.length > 0 ? (
              fertilizerData.map((row: FertilizerItem) => (
                <FertilizerDataRow
                  rowData={row}
                  key={`row-key-${row.id}`}
                  replaceRowData={handleReplaceRowData}
                  reloadData={reloadData}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} sx={{ padding: 0, border: 'none !important' }}>
                  <InfoNotification marginBottom="0px">{t('fertilizerInvoiceManagement.noFertilizerInvoiceData')}</InfoNotification>
                </TableCell>
              </TableRow>

            )}
          </TableBody>
        </Table>
      </ResponsiveTableWrap>
    </DataTableContainer>
  )
}
