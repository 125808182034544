import React, { useContext, useEffect, useState } from 'react'
import * as ls from 'local-storage'
import { API } from '../../../providers/API'
import { FertilizerItem } from '../FertilizerInvoiceManagement/FertilizerInvoiceManagement.types'
import { CarbonEnrollmentContext } from '../../../providers/CarbonEnrollmentProvider'
import { Loading } from '../../Loading/Loading'
import { FertilizerDataListing } from '../FertilizerInvoiceManagement/FertilizerInvoiceDataListing'
import { FertilizerDataEntryForm } from '../FertilizerInvoiceManagement/FertilizerInvoiceDataEntryForm'

export const FertilizerInvoiceManagement = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { activeCompany, fertilizerData, setFertilizerData } = useContext(CarbonEnrollmentContext)
  const loadData = () => {
    setIsLoading(true)
    API.get(`/fertilizerDataListing/${activeCompany?.id}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then(({ data }: {data: FertilizerItem[]}) => {
      setFertilizerData(data)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    loadData()
  }, [activeCompany])

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <FertilizerDataEntryForm action="create" rowData={null} reloadData={loadData} />
      {fertilizerData && (
      <FertilizerDataListing
        fertilizerData={fertilizerData}
        setFertilizerData={setFertilizerData}
        reloadData={loadData}
      />
      )}
    </>
  )
}
