import React, { useState } from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Collapse from '@mui/material/Collapse'
import * as ls from 'local-storage'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash, faDownload } from '@fortawesome/free-solid-svg-icons'
import { FuelItem } from './FuelManagement.types'

import { API } from '../../../providers/API'
import { theme } from '../../../theme/theme'
import { FuelDataEntryForm } from './FuelDataEntryForm'

export const FuelDataRow = (
  {
    rowData, replaceRowData, reloadFuelData,
  } : {
    rowData: FuelItem,
    replaceRowData:({ newData } : {newData: FuelItem}) => void
    reloadFuelData:() => void
    },
) => {
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  const deleteRow = () => {
    setIsDeleting(true)
    API.delete(`/fuelDataDelete/${rowData.id}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then(
      () => {
        reloadFuelData()
        setIsDeleting(false)
      },
    )
  }

  return (
    <>
      <TableRow
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell>
          &#8470;{rowData.invoiceNumber} <a href={`https://api.agrovar.bg/public/storage/${rowData.URL}`} aria-label="download" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faDownload} /></a>
        </TableCell>
        <TableCell align="center">{rowData.year}</TableCell>
        <TableCell align="center">{rowData.date}</TableCell>
        <TableCell align="center">{rowData.petrol}</TableCell>
        <TableCell align="center">{rowData.diesel}</TableCell>
        <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
          <Button
            sx={{ minWidth: 'auto' }}
            variant="contained"
            type="button"
            color="primary"
            onClick={() => setIsEditOpen((prev) => !prev)}
          ><FontAwesomeIcon icon={faEdit} size="lg" />
          </Button>
          <LoadingButton
            sx={{ minWidth: 'auto', marginLeft: theme.space.sm }}
            variant="contained"
            type="button"
            color="error"
            loadingPosition="start"
            loading={isDeleting}
            onClick={deleteRow}
          ><FontAwesomeIcon icon={faTrash} size="lg" />
          </LoadingButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, borderWidth: isEditOpen ? 1 : 0 }}
          colSpan={6}
        >
          <Collapse in={isEditOpen} timeout="auto" unmountOnExit sx={{ padding: `${theme.space.md} 0;` }}>
            <FuelDataEntryForm
              action="edit"
              rowData={rowData}
              replaceRowData={replaceRowData}
              toggleAddForm={() => setIsEditOpen(false)}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
