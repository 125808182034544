import React, { useContext, useEffect, useState } from 'react'
import * as ls from 'local-storage'
import { FuelDataEntryForm } from '../FuelManagement/FuelDataEntryForm'
import { API } from '../../../providers/API'
import { FuelItem } from '../FuelManagement/FuelManagement.types'
import { CarbonEnrollmentContext } from '../../../providers/CarbonEnrollmentProvider'
import { Loading } from '../../Loading/Loading'
import { FuelDataListing } from '../FuelManagement/FuelDataListing'

export const Fuel = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { activeCompany, fuelData, setFuelData } = useContext(CarbonEnrollmentContext)
  const loadFuelData = () => {
    setIsLoading(true)
    API.get(`/fuelDataListing/${activeCompany?.id}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then(({ data }: {data: FuelItem[]}) => {
      setFuelData(data)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    loadFuelData()
  }, [activeCompany])

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <FuelDataEntryForm action="create" rowData={null} reloadFuelData={loadFuelData} />
      {fuelData && (
      <FuelDataListing
        fuelData={fuelData}
        setFuelData={setFuelData}
        reloadFuelData={loadFuelData}
      />
      )}
    </>
  )
}
