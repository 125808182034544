import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { DataTableContainer, ResponsiveTableWrap } from '../../CropRotation/CropRotation.styles'
import { FuelItem } from './FuelManagement.types'
import { InfoNotification } from '../../SuccessNotification/SuccessNotification.styles'
import { FuelDataRow } from './FuelDataRow'

export const FuelDataListing = (
  {
    fuelData, setFuelData, reloadFuelData,
  } : {
    fuelData: FuelItem[],
    setFuelData: Dispatch<SetStateAction<FuelItem[]>>
    reloadFuelData: () => void
    },
) => {
  const { t } = useTranslation()

  const handleReplaceRowData = ({ newData } : {newData: FuelItem}) => {
    const updatedData = fuelData.map((item: FuelItem) => {
      if (item.id === newData.id) {
        return newData
      }
      return item
    })

    setFuelData(updatedData)
  }

  return (
    <DataTableContainer>
      <ResponsiveTableWrap>
        <Table sx={{ minWidth: 650, borderCollapse: 'collapse' }} aria-label={t('fuelManagement.cropRotation')}>
          <TableHead>
            <TableRow>
              <TableCell>{t('fuelManagement.invoice')}</TableCell>
              <TableCell align="center">{t('general.year')}</TableCell>
              <TableCell align="center">{t('fuelManagement.date')}</TableCell>
              <TableCell align="center">{t('fuelManagement.petrol')}</TableCell>
              <TableCell align="center">{t('fuelManagement.diesel')}</TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {fuelData.length > 0 ? (
              fuelData.map((row: FuelItem) => (
                <FuelDataRow
                  rowData={row}
                  key={`row-key-${row.id}`}
                  replaceRowData={handleReplaceRowData}
                  reloadFuelData={reloadFuelData}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} sx={{ padding: 0, border: 'none !important' }}>
                  <InfoNotification marginBottom="0px">{t('fuelManagement.noFuelData')}</InfoNotification>
                </TableCell>
              </TableRow>

            )}
          </TableBody>
        </Table>
      </ResponsiveTableWrap>
    </DataTableContainer>
  )
}
