import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Collapse from '@mui/material/Collapse'
import { Button } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { YieldItem } from './Yield.types'
import { theme } from '../../../theme/theme'
import { YieldDataEntryForm } from './YieldDataEntryForm'

export const YieldRow = ({
  rowData, replaceRowData,
} : {
  rowData: YieldItem,
  replaceRowData:({ newData } : {newData: YieldItem}) => void
  }) => {
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false)
  const { t } = useTranslation()

  return (
    <>
      <TableRow
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell>{rowData.year}</TableCell>
        <TableCell align="center">{t(`crops.${rowData.cropName}`)}</TableCell>
        <TableCell align="center">{rowData.area}</TableCell>
        <TableCell align="center">{rowData.quantity}</TableCell>
        <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
          <Button
            sx={{ minWidth: 'auto' }}
            variant="contained"
            type="button"
            color="primary"
            onClick={() => setIsEditOpen((prev) => !prev)}
          ><FontAwesomeIcon icon={faEdit} size="lg" />
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, borderWidth: isEditOpen ? 1 : 0 }}
          colSpan={5}
        >
          <Collapse in={isEditOpen} timeout="auto" unmountOnExit sx={{ padding: `${theme.space.md} 0;` }}>
            <YieldDataEntryForm
              rowData={rowData}
              replaceRowData={replaceRowData}
              toggleAddForm={() => setIsEditOpen(false)}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
