import styled, { css } from 'styled-components'
import TabsListUnstyled from '@mui/base/TabsListUnstyled'
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled'
import { theme } from '../../theme/theme'
import { FieldItemRow } from '../FieldsListing/FieldsListing.styles'

export const HeadContent = styled.div`
    flex-grow: 0;
    flex-shrink: 0;

    ${theme.mediaQueries.lgMin} {
        width: 250px;
    }
`
export const MainWeatherData = styled.div<{fullHeight?: boolean}>(
  ({ fullHeight = false }) => css`

    display: flex;
    justify-content: space-between;
    align-items: center;
    height: ${fullHeight ? '100%' : 'auto'};

    .icon {
        width: 35%;
        margin: 0 ${theme.space.sm} 0 0;
        flex-shrink: 0;
        color: ${theme.colors.orangeUI};

        img {
            max-width: 130px;
            height: auto;
        }
    }

    .temperature {
        flex-grow: 1;
        text-align: center;
        font-size: 14px;

        & > * {
            display: block;
        }

        .mainTemp {
            font-size: 26px;
        }
    }
`,
)

export const WeatherTable = styled.div<{flatItem?: boolean}>(
  ({ flatItem }) => css`
    margin-top: ${theme.space.sm};

  ${flatItem && (
    `display: flex; 
    justify-content: space-between; 
    flex-wrap: wrap; 
    flex-grow: 1; 
    

    ${theme.mediaQueries.lgMin} {
        border-left: 3px dotted ${theme.colors.blueBGUILight}; 
        padding-left: ${theme.space.md}; 
        margin-left: ${theme.space.md};
    }
    `
  )}

    div:not(.row) {
        font-size: 0.8em;
        padding: ${theme.space.xs} ${theme.space.sm};

        &:nth-child(even) {
            background: ${theme.colors.blueBGUILight};
        }
    }

    div.row {
        display: block;
        width: 100%;

        ${theme.mediaQueries.lgMin} {
            display: flex;
            align-items: center;

            &:nth-child(even) {
                background: ${theme.colors.blueBGUILight};
            }
        }
        
        div {
            width: 100%;
            
            font-weight: bold;

            ${theme.mediaQueries.lgMin} {
                width: 50%;
                background: none !important;
            }
        }
    }
`,
)

export const WeatherDateHolder = styled.div`
background-color: ${theme.colors.blueBGUILight};
padding: ${theme.space.sm};
border-radius: '5px';
margin-bottom: ${theme.space.md};
text-align: center;
font-weight: bold;
font-size: 30px;
`

export const WeatherItem = styled.div<{flatItem?: boolean, hourly?: boolean, link?: boolean}>(
  ({ flatItem, hourly, link }) => css`
      background: #FFF;
      color: #2C2C2C;
      border-radius: 10px;
      padding: ${theme.space.sm};
      cursor: ${link ? 'pointer' : 'normal'};

    ${flatItem && (
    `
            ${theme.mediaQueries.lgMin} {
                display: flex; 
                justify-content: space-between;
            }
        `
  )}
    
    ${hourly && (
    `
            display: flex;
            align-items: center;
            width: 100% !important;

            ${theme.mediaQueries.smMax} {
                display: block;
            }

            ${WeatherDateHolder} {
                margin: 0;

                ${theme.mediaQueries.lgMax} {
                    font-size: 20px;
                }

                ${theme.mediaQueries.smMax} {
                    margin: 0 0 ${theme.space.md} 0;
                }
            }

            ${MainWeatherData} {
                display: flex;
                margin: 0 ${theme.space.md};

                ${theme.mediaQueries.smMax} {
                    justify-content: center;
                    margin: 0 0 ${theme.space.md};
                }

                .temperature {
                    text-align: left;

                    ${theme.mediaQueries.smMax} {
                        flex-grow: 0;
                    }
                }

                .icon {
                    width: auto;
                    height: 50px;

                    img {
                        width: auto;
                        height: 100%;
                    }
                }

                ${theme.mediaQueries.lgMax} {
                    .mainTemp {
                        font-size: 20px;
                    }

                    .icon {
                        height: 40px;
                    }
                }
            }

            ${WeatherTable} {
                display: flex;
                flex-grow: 1;
                justify-content: end;

                ${theme.mediaQueries.smMax} {
                    justify-content: center;
                }

                & > div {
                    background: none;

                    ${theme.mediaQueries.lgMin} {
                        font-size: 1.1em;
                        margin: 0 0 0 ${theme.space.md};
                    }
                }
            }
        `
  )}
  
      ${FieldItemRow} {
          margin-bottom: ${theme.space.sm};
          background: ${theme.colors.blueBGUILight};
  
          & .svgHolder {
              height: 45px;
              width: 60px;
          }
  
          & .name {
              padding: 0 ${theme.space.md};
              font-size: 0.8em;
          }
      }
  `,
)

export const Tab = styled(TabUnstyled)`
    color: #fff;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 600;
    background-color: transparent;
    width: 100%;
    padding: 10px 12px;
    margin: 6px 6px;
    border: none;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    transition: background 0.3s;

    &:disabled {
        cursor: pointer;
        opacity: 0.5;

    }

    
    ${theme.mediaQueries.smMax} {
        font-size: 12px;
        padding: 6px 5px;
    }
  
    &:hover {
      background-color: ${theme.colors.blueUI};
    }
  
    &:focus {
      color: #fff;
      outline: 3px solid ${theme.colors.blueUI};
    }

    &.${tabUnstyledClasses.selected} {
    background-color: #fff;
    color: ${theme.colors.blueBGUI};
  }
  `

export const TabsList = styled(TabsListUnstyled)(
  () => `
    background-color: ${theme.colors.blueBGUI};
    border-radius: 12px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;

    ${theme.mediaQueries.mdMax} {
        &.carbonEnrollment {
            flex-direction: column;
        }
    }
    `,
)
