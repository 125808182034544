import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as ls from 'local-storage'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'
import type {} from '@mui/x-date-pickers/themeAugmentation'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons'
import { AddFormHeading, DataTableFormFieldsGrid } from '../../CropRotation/CropRotation.styles'
import { API } from '../../../providers/API'
import { theme } from '../../../theme/theme'
import { YieldItem } from './Yield.types'

export const YieldDataEntryForm = ({
  rowData, replaceRowData, reloadData, toggleAddForm,
}: {
    rowData: YieldItem | null
    replaceRowData?: ({ newData } : {newData: YieldItem}) => void
    reloadData?: () => void
    toggleAddForm?: () => void
  }) => {
  const { t } = useTranslation()

  const [isSaving, setIsSaving] = useState<boolean>(false)

  const ValidationSchema = Yup.object().shape({
    quantity: Yup.number().required(t('yieldManagement.validationMessages.quantity')),
  })

  const separatorStyles = {
    borderBottom: `1px dotted ${theme.colors.blueUI}`,
    paddingBottom: theme.space.md,
    marginBottom: theme.space.md,
  }

  return (
    <Formik
      initialValues={{
        quantity: rowData?.quantity ?? '',
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values) => {
        setIsSaving(true)
        const formDataToSave = {
          yieldId: rowData?.id,
          quantity: values.quantity,
        }

        API.post('/yieldEdit', formDataToSave, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then(
          ({ data }: {data: YieldItem}) => {
            if (reloadData) {
              reloadData()
            }
            if (toggleAddForm) {
              toggleAddForm()
            }
            if (replaceRowData) {
              replaceRowData({ newData: data })
            }
            setIsSaving(false)
          },
        ).catch(() => {
          setIsSaving(false)
        })
      }}
    >
      {({
        handleSubmit, errors, setFieldValue, values,
      }) => (
        <form
          onSubmit={handleSubmit}
          style={separatorStyles}
        >
          <AddFormHeading>{t('fuelInventory.editRowTitle')}</AddFormHeading>
          <DataTableFormFieldsGrid>
            <TextField
              label={t('general.quantity')}
              name="quantity"
              value={values.quantity}
              onChange={(newValue) => {
                setFieldValue('quantity', newValue.target.value)
              }}
              fullWidth
              error={!!errors.quantity}
              helperText={errors.quantity ? errors.quantity : ''}
            />
          </DataTableFormFieldsGrid>

          <LoadingButton
            variant="contained"
            type="button"
            color="primary"
            loading={isSaving}
            loadingPosition="start"
            endIcon={<FontAwesomeIcon icon={faAnglesRight} />}
            onClick={() => handleSubmit()}
          >
            {t('general.save')}
          </LoadingButton>
        </form>
      )}
    </Formik>
  )
}
