import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as ls from 'local-storage'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { DataTableContainer, ResponsiveTableWrap } from '../../CropRotation/CropRotation.styles'
import { API } from '../../../providers/API'
import { Loading } from '../../Loading/Loading'
import { CarbonEnrollmentContext } from '../../../providers/CarbonEnrollmentProvider'
import { YieldItem } from '../YieldManagement/Yield.types'
import { InfoNotification } from '../../SuccessNotification/SuccessNotification.styles'
import { YieldRow } from '../YieldManagement/YieldRow'

export const YieldManagement = () => {
  const { activeCompany } = useContext(CarbonEnrollmentContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [yieldData, setYieldData] = useState<any>([])
  const { t } = useTranslation()

  useEffect(() => {
    API.get(`/yieldListing/${activeCompany?.id}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then(({ data }: {data: YieldItem[]}) => {
      setYieldData(data)
      setIsLoading(false)
    })
  }, [activeCompany])

  const handleReplaceRowData = ({ newData } : {newData: YieldItem}) => {
    const updatedData = yieldData.map((item: YieldItem) => {
      if (item.id === newData.id) {
        return newData
      }
      return item
    })

    setYieldData(updatedData)
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <DataTableContainer>
      <ResponsiveTableWrap>
        <Table sx={{ minWidth: 650, borderCollapse: 'collapse' }} aria-label={t('fertilizerInvoiceManagement.title')}>
          <TableHead>
            <TableRow>
              <TableCell>{t('general.year')}</TableCell>
              <TableCell align="center">{t('general.crop')}</TableCell>
              <TableCell align="center">{t('yieldManagement.totalArea')}</TableCell>
              <TableCell align="center">{t('general.quantity')}</TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {yieldData.length > 0 ? (
              yieldData.map((row: YieldItem) => (
                <YieldRow
                  rowData={row}
                  key={`row-key-${row.id}`}
                  replaceRowData={handleReplaceRowData}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} sx={{ padding: 0, border: 'none !important' }}>
                  <InfoNotification marginBottom="0px">{t('yieldManagement.noData')}</InfoNotification>
                </TableCell>
              </TableRow>

            )}
          </TableBody>
        </Table>
      </ResponsiveTableWrap>
    </DataTableContainer>
  )
}
