import React, { useContext, useEffect, useState } from 'react'
import { FieldArray, Formik } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faTrash } from '@fortawesome/free-solid-svg-icons'
import * as ls from 'local-storage'
import * as Yup from 'yup'
import LoadingButton from '@mui/lab/LoadingButton'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import type {} from '@mui/x-date-pickers/themeAugmentation'
import { InputField } from '../../FormElements/InputField'
import { FertilizerUsageItem, FertilizerUsageItemData, updateFertilizerUsageEntriesArgs } from './FertilizerUsage.types'
import { FertilizerItem as FertilizerInventoryItem } from '../InventoryOverlays/Fertilizers/Fertilizer.types'
import { SimpleBox } from '../../../App.styles'
import {
  CollapsibleContainer, CollapsibleCOntainerButton, FakeLoadingInput, FertilizerInputsRow, Header,
} from './FertilizerUsage.styles'
import { API } from '../../../providers/API'
import { CarbonEnrollmentContext } from '../../../providers/CarbonEnrollmentProvider'
import { FieldsWithCropOverlay } from './FieldsWidthCropOverlay'
import { formatDateStrings } from '../../../utils/formatDateStrings'

interface FieldSelectProps {
  label: string
  id: string
}

export const FertilizerUsageDataRow = ({
  rowData,
  fertilizerInventory,
  fetchFertilizerInventory,
  isFetchingFertilizerInventory,
  updateFertilizerUsageEntries,
} : {
    rowData: FertilizerUsageItem,
    fertilizerInventory: FertilizerInventoryItem[]
    fetchFertilizerInventory: () => void
    isFetchingFertilizerInventory: boolean
    updateFertilizerUsageEntries: ({ cropId, year, data }: updateFertilizerUsageEntriesArgs) => void
    }) => {
  const { activeCompany } = useContext(CarbonEnrollmentContext)
  const [isOpen, setIsOpen] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [isDeleting, setIsDeleting] = useState<number | null>(null)
  const [inventoryIdSelectOptions, setInventoryIdSelectOptions] = useState<FieldSelectProps[]>([])
  const [selecteInventories, setSelecteInventories] = useState<FieldSelectProps[]>([])
  const [entryValues, setEntryValues] = useState({
    entries: [
      {
        inventoryId: 0,
        quantity: 0,
        date: '',
      },
    ],
  })

  const { t } = useTranslation()
  useEffect(() => {
    const allEntryValues = rowData.usage.map((entry: FertilizerUsageItemData) => (
      {
        inventoryId: entry.fertilizerInventoryId,
        quantity: entry.quantity,
        date: entry.usageDate,
      }
    ))
    setEntryValues({ entries: [...allEntryValues] })

    const inventoryIdSelectOptionsObject: FieldSelectProps[] = fertilizerInventory.map(
      (inventoryItem: FertilizerInventoryItem) => {
        const percentageN = inventoryItem.N !== null ? `N - ${inventoryItem.N}%,` : ''
        const percentageP = inventoryItem.P !== null ? ` P - ${inventoryItem.P}%,` : ''
        const percentageK = inventoryItem.K !== null ? ` K - ${inventoryItem.K}%` : ''
        const labelPercentages = `${percentageN}${percentageP}${percentageK}`
        return (
          { id: inventoryItem.id.toString(), label: `${inventoryItem.type} ${inventoryItem.quantity}${t('general.kg')} (${labelPercentages})` }
        )
      },
    )

    const selectedInventories = rowData.usage.map(
      (entry: FertilizerUsageItemData) => (
        inventoryIdSelectOptionsObject.find(
          (option: FieldSelectProps) => option.id === entry.fertilizerInventoryId.toString(),
        ) || { id: '', label: '' }
      ),
    )

    setInventoryIdSelectOptions(inventoryIdSelectOptionsObject)
    setSelecteInventories(selectedInventories)

    setIsLoading(false)
  }, [fertilizerInventory])

  const handleDeleteItem = (entryId: number) => {
    setIsDeleting(entryId)
    API.delete(`/fertilizerUsageDelete/${entryId}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      setIsDeleting(null)
      updateFertilizerUsageEntries(
        { cropId: rowData.cropId, year: rowData.year, data: response.data },
      )
      fetchFertilizerInventory()
    }).catch(() => {
      setIsDeleting(null)
    })
  }

  const ValidationSchema = Yup.object().shape({
    entries: Yup.array()
      .of(
        Yup.object().shape({
          inventoryId: Yup.number().required(t('fertilizerUsage.fertilizerInventoryId')),
          quantity: Yup.number().required(t('fertilizerUsage.quantity')),
          date: Yup.string().required(t('fertilizerUsage.date')),
        }),
      ),
  })

  return (
    <SimpleBox hasMarginBottom>
      <Header>
        <h3>{rowData.year} / {t(`crops.${rowData.cropName}`)}</h3>
        <div>
          <FieldsWithCropOverlay fieldsData={rowData.fields} cropName={rowData.cropName} />
          <CollapsibleCOntainerButton type="button" onClick={() => setIsOpen((prev: boolean) => !prev)}>
            <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
          </CollapsibleCOntainerButton>
        </div>
      </Header>
      {!isLoading && (
      <CollapsibleContainer isOpen={isOpen}>
        <Formik
          initialValues={entryValues}
          validationSchema={ValidationSchema}
          validateOnChange={false}
          enableReinitialize
          onSubmit={(values) => {
            const entriesToSubmit = values.entries.map((entry: any, index: number) => (
              {
                inventoryId: parseInt(entry.inventoryId),
                quantity: parseInt(entry.quantity),
                date: formatDateStrings(new Date(entry.date)),
                id: rowData.usage[index]?.id ?? null,
              }
            ))

            const submitData = {
              companyId: activeCompany?.id,
              cropId: rowData.cropId,
              year: rowData.year,
              entries: entriesToSubmit,
            }

            setIsSaving(true)
            API.post('/fertilizerUsageSave', submitData, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
              setIsSaving(false)
              updateFertilizerUsageEntries(
                { cropId: rowData.cropId, year: rowData.year, data: response.data },
              )
              fetchFertilizerInventory()
            }).catch(() => {
              setIsSaving(false)
            })
          }}
        >
          {({
            handleSubmit, values, setFieldValue, errors,
          }) => (
            <form onSubmit={handleSubmit}>
              <>
                <FieldArray
                  name="fields"
                  render={() => (
                    <>
                      {values.entries.map((asdasd: any, index: number) => (
                        <FertilizerInputsRow key={index}>
                          {isFetchingFertilizerInventory ? (<FakeLoadingInput />) : (
                            <Autocomplete
                              disablePortal
                              options={inventoryIdSelectOptions}
                              defaultValue={selecteInventories?.[index]}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={t('fertilizerUsage.fertilizerInventoryId')}
                                // @ts-ignore
                                  error={!!errors.entries?.[index]?.inventoryId}
                                // @ts-ignore
                                  helperText={errors.entries?.[index]?.inventoryId}
                                />
                              )}
                              fullWidth
                              onChange={(event, newValue: FieldSelectProps | null) => {
                                setFieldValue(`entries[${index}].inventoryId`, newValue?.id)
                              }}
                            />
                          )}
                          <InputField label={t('fertilizerUsage.quantity')} name={`entries[${index}].quantity`} id={`entries[${index}].quantity`} type="text" />
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              label={t('general.date')}
                              inputFormat="dd/MM/yyyy"
                              value={values.entries[index].date}
                              onChange={(newValue) => {
                                setFieldValue(`entries[${index}].date`, newValue)
                              }}
                              renderInput={(params: any) => (
                                <TextField
                                  fullWidth
                                  {...params}
                                  // @ts-ignore
                                  error={!!errors.entries?.[index]?.date}
                                  // @ts-ignore
                                  helperText={errors.entries?.[index]?.date ? errors.entries?.[index]?.date : ''}
                                />
                              )}
                            />
                          </LocalizationProvider>
                          {rowData.usage[index]?.id && (
                          <LoadingButton
                            style={{ marginBottom: '16px' }}
                            type="button"
                            variant="contained"
                            color="error"
                            onClick={() => handleDeleteItem(rowData.usage[index].id)}
                            loading={isDeleting === rowData.usage[index].id}
                            loadingPosition="start"
                          ><FontAwesomeIcon icon={faTrash} />
                          </LoadingButton>
                          )}
                        </FertilizerInputsRow>
                      ))}
                    </>
                  )}
                />
                <FertilizerInputsRow>
                  <Button
                    variant="contained"
                    type="button"
                    color="primary"
                    onClick={() => setEntryValues((prev: any) => (
                      {
                        entries: [...prev.entries, { inventoryId: '', quantity: '', date: '' }],
                      }
                    ))}
                  >{t('fertilizerUsage.addEntry')}
                  </Button>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    color="primary"
                    loading={isSaving}
                    loadingPosition="start"
                  >
                    {t('general.save')}
                  </LoadingButton>
                </FertilizerInputsRow>
              </>
            </form>
          )}
        </Formik>
      </CollapsibleContainer>
      )}
    </SimpleBox>
  )
}
