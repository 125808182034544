import styled from 'styled-components'
import { theme } from '../../../theme/theme'

export const InvoiceChoiceContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: ${theme.space.md};
    margin-bottom: ${theme.space.md};
    
    ${theme.mediaQueries.mdMax} {
        flex-direction: column;
    }

    & .MuiFormControl-root {
        margin: 0 !important;
    }

    .separator {
        grow: 0;
        align-self: center;
    }

    .uploadHolder {
        display: flex;
        flex-direction: column;
        width: 100%;

        .MuiButton-root {
        height: 100%;
        }
    }

    .errorMessages {
        color: ${theme.colors.orangeUI};
        margin: ${theme.space.xs} ${theme.space.md} 0;
        font-size: 0.75rem;
    }
`
