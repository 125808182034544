import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { DataTableContainer, ResponsiveTableWrap } from '../../../CropRotation/CropRotation.styles'
import { Loading } from '../../../Loading/Loading'
import { CarbonEnrollmentContext } from '../../../../providers/CarbonEnrollmentProvider'
import { InfoNotification } from '../../../SuccessNotification/SuccessNotification.styles'
import { FuelInventoryRow } from './FuelInventoryRow'
import { FuelInventoryItem } from './Fuel.types'

export const FuelInventoryListing = () => {
  const {
    fuelInventoryData,
    setFuelInventoryData,
  } = useContext(CarbonEnrollmentContext)
  const { t } = useTranslation()

  const handleReplaceRowData = ({ newData } : {newData: FuelInventoryItem}) => {
    const updatedData = fuelInventoryData.map((item: FuelInventoryItem) => {
      if (item.id === newData.id) {
        return newData
      }
      return item
    })

    setFuelInventoryData(updatedData)
  }

  if (!fuelInventoryData) {
    return <Loading />
  }

  return (
    <DataTableContainer>
      <ResponsiveTableWrap>
        <Table sx={{ minWidth: 650, borderCollapse: 'collapse' }} aria-label={t('fertilizerInvoiceManagement.title')}>
          <TableHead>
            <TableRow>
              <TableCell>{t('general.year')}</TableCell>
              <TableCell align="center">{t('fuelInventory.petrol')}</TableCell>
              <TableCell align="center">{t('fuelInventory.petrolUsed')}</TableCell>
              <TableCell align="center">{t('fuelInventory.diesel')}</TableCell>
              <TableCell align="center">{t('fuelInventory.dieselUsed')}</TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {fuelInventoryData.length > 0 ? (
              fuelInventoryData.map((row: FuelInventoryItem) => (
                <FuelInventoryRow
                  rowData={row}
                  key={`row-key-${row.id}`}
                  replaceRowData={handleReplaceRowData}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} sx={{ padding: 0, border: 'none !important' }}>
                  <InfoNotification marginBottom="0px">{t('fuelInventory.noData')}</InfoNotification>
                </TableCell>
              </TableRow>

            )}
          </TableBody>
        </Table>
      </ResponsiveTableWrap>
    </DataTableContainer>
  )
}
