import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CarbonEnrollmentProvider } from '../providers/CarbonEnrollmentProvider'
import { PageHeadingContainer } from '../components/PageHeading/PageHeading.styles'
import { HeaderCompanyInfo } from '../components/CarbonEnrollment/HeaderCompanyInfo/HeaderCompanyInfo'
import { CarbonEnrollmentContent } from '../components/CarbonEnrollment/CarbonEnrollmentContent'
import { Loading } from '../components/Loading/Loading'

export const CarbonEnrollment = () => {
  const [missingCompanyInfo, setMissingCompanyInfo] = useState<boolean | undefined>(undefined)
  const { t } = useTranslation()

  return (
    <CarbonEnrollmentProvider>
      <PageHeadingContainer>
        <h1>{t('navigation.carbonEnrollment')}</h1>
        <HeaderCompanyInfo setMissingCompanyInfo={setMissingCompanyInfo} />
      </PageHeadingContainer>
      {missingCompanyInfo === undefined ? <Loading /> : <CarbonEnrollmentContent />}
    </CarbonEnrollmentProvider>
  )
}
