import React, { useContext } from 'react'
import { CarbonEnrollmentContext } from '../../../providers/CarbonEnrollmentProvider'
import { CropRotation as CropRotationListing } from '../../CropRotation/CropRotation'

export const CropRotation = () => {
  const {
    activeCompany,
  } = useContext(CarbonEnrollmentContext)

  return (
    <>
      <CropRotationListing companyId={activeCompany?.id} isEnrollment />
    </>

  )
}
