import React from 'react'
import { useTranslation } from 'react-i18next'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { FertilizerItem } from './Fertilizer.types'

export const FertilizersInventoryRow = ({
  rowData,
} : {
  rowData: FertilizerItem,
  }) => {
  const { t } = useTranslation()

  return (
    <>
      <TableRow
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell>{rowData.year}</TableCell>
        <TableCell align="center">{rowData.type}</TableCell>
        <TableCell align="center">
          {rowData.N && `${t('fertilizerInvoiceManagement.N')} ${rowData.N}%`}<br />
          {rowData.P && `${t('fertilizerInvoiceManagement.P')} ${rowData.P}%`}<br />
          {rowData.K && `${t('fertilizerInvoiceManagement.K')} ${rowData.K}%`}
        </TableCell>
        <TableCell align="center">{rowData.quantity}</TableCell>
      </TableRow>
    </>
  )
}
