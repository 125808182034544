import React from 'react'
import { useTranslation } from 'react-i18next'
import TabsUnstyled from '@mui/base/TabsUnstyled'
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled'
import { CarbonEnrollmentContext } from '../../providers/CarbonEnrollmentProvider'
import { Tab, TabsList } from '../WeatherListing/WeatherListing.styles'
import { CropRotation } from './Steps/CropRotation'
import { Fuel } from './Steps/Fuel'
import { FertilizerInvoiceManagement } from './Steps/FertilizerInvoiceManagement'
import { YieldManagement } from './Steps/Yield'
import { CompanyInfoForm } from './CompanyInfoForm/CompanyInfoForm'
import { InventoryOverlaysContainer } from './InventoryOverlays/InventoryOverlaysContainer'
import { FertilizerUsageManagement } from './Steps/FertilizerUsageManagement'

export const CarbonEnrollmentContent = () => {
  const { registeredCompanies } = React.useContext(CarbonEnrollmentContext)
  const { t } = useTranslation()

  if (registeredCompanies.length === 0) {
    return <CompanyInfoForm />
  }

  return (
    <TabsUnstyled defaultValue={0}>
      <TabsList className="carbonEnrollment">
        <Tab>{t('carbonEnrollment.steps.cropRotation')}</Tab>
        <Tab>{t('carbonEnrollment.steps.fuel')}</Tab>
        <Tab>{t('carbonEnrollment.steps.fertilizersInvoices')}</Tab>
        <Tab>{t('carbonEnrollment.steps.fertilizersUsage')}</Tab>
        <Tab>{t('carbonEnrollment.steps.yield')}</Tab>
      </TabsList>
      <InventoryOverlaysContainer />
      <TabPanelUnstyled value={0}>
        <CropRotation />
      </TabPanelUnstyled>
      <TabPanelUnstyled value={1}>
        <Fuel />
      </TabPanelUnstyled>
      <TabPanelUnstyled value={2}>
        <FertilizerInvoiceManagement />
      </TabPanelUnstyled>
      <TabPanelUnstyled value={3}>
        <FertilizerUsageManagement />
      </TabPanelUnstyled>
      <TabPanelUnstyled value={4}>
        <YieldManagement />
      </TabPanelUnstyled>
    </TabsUnstyled>
  )
}
