import { createGlobalStyle } from 'styled-components'
import { theme } from './theme'
import { SimpleBox } from '../App.styles'
import { ChartHeader } from '../components/NdviFIeld/NdviField.styles'

export const GlobalStyle = createGlobalStyle`
    

    html {
        font-family: ${theme.fonts.default};
        text-size-adjust: 100%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        box-sizing: border-box;
        background: #004D5F;
        background-repeat:no-repeat;
        mix-blend-mode: normal;
    }

    body {
        background: radial-gradient(75.65% 75.65% at 50% -2.19%, #006B7E 0%, #004D5F 100%);
        font-size: 16px;
    }

    body, html {
        padding: 0;
        margin: 0;
        color: #FFF;
    }

    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    a {
        color: ${theme.colors.blueUI};
    }

    h1 {
        color: #FFF;
        font-weight: normal;
        font-size: ${theme.fontSizes.xxl};
        margin: 0 0 ${theme.space.md};
        padding: ${theme.space.sm};
        background: rgba(6, 192, 226, 0.14);
        border-radius: 10px;
    }

    h2 {
        margin: 0 0 ${theme.space.md};
        font-size: ${theme.fontSizes.lg};
    }

    .MuiFormHelperText-root.Mui-error,
    .MuiInputLabel-root.Mui-error {
        color: ${theme.colors.orangeUI} !important;
    }

    .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
        border-color: ${theme.colors.orangeUI} !important;
    }

    .MuiInputLabel-root.Mui-focused {
        color: #FFF !important;
    }

    .MuiOutlinedInput-root input,
    .MuiOutlinedInput-root textarea,
    .MuiInputLabel-root,
    .MuiInputAdornment-root > .MuiIconButton-root svg,
    .MuiAutocomplete-endAdornment > .MuiIconButton-root svg,
    .MuiChip-deleteIcon {
        color: #FFF !important;

        ${SimpleBox} & {
            color: #000 !important;
        }


        ${ChartHeader} & {
            color: ${theme.colors.blueUI} !important;
        }
    }

    .MuiInputAdornment-root > .MuiIconButton-root svg,
    .MuiAutocomplete-endAdornment > .MuiIconButton-root svg {
        
    }

    ${SimpleBox} {
        & [class*='MuiSvgIcon-root'] {
            color: ${theme.colors.blueUI} !important;
        }
    }

    .MuiCalendarPicker-root {
        & [class*='MuiSvgIcon-root'] {
            color: inherit !important;
        }
    }

    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border-color: ${theme.colors.blueUI} !important;
        color: #FFF !important;
        border-width: 3px;

        input {
            color: #FFF !important;
        }
    }

    .MuiLoadingButton-root.Mui-disabled {
        background: ${theme.colors.orangeUI} !important;
    }

    .TabUnstyled-root.unlockPremium {
        background: ${theme.colors.orangeUI} !important;
        color: #FFF !important;
        opacity: 1 !important;
    }

    .noBreak {
        white-space: nowrap;
    }

    .visuallyHidden {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
    }

    @-webkit-keyframes autofill {
        0%,100% {
            color: #666;
            background: transparent;
        }
    }
    
    input:-webkit-autofill,
    textarea:-webkit-autofill,
    select:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px #024454 inset !important;
        -webkit-text-fill-color: white !important;
    }

    .MuiTableSortLabel-root.Mui-active,
    .MuiTableSortLabel-root:hover {
        color: ${theme.colors.blueUI} !important;

        & svg {
            color: ${theme.colors.blueUI} !important;
        }
    }
`
