import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as ls from 'local-storage'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import type {} from '@mui/x-date-pickers/themeAugmentation'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight, faUpload } from '@fortawesome/free-solid-svg-icons'
import { AddFormHeading, DataTableFormFieldsGrid } from '../../CropRotation/CropRotation.styles'
import { API } from '../../../providers/API'
import { theme } from '../../../theme/theme'
import { CarbonEnrollmentContext } from '../../../providers/CarbonEnrollmentProvider'
import { FuelItem } from './FuelManagement.types'
import { formatDateStrings } from '../../../utils/formatDateStrings'
import { InvoiceChoiceContainer } from './FuelManagement.styles'

interface FieldSelectProps {
  label: string
  id: string
}

export const FuelDataEntryForm = ({
  action, rowData, replaceRowData, reloadFuelData, toggleAddForm,
}: {
    action: 'edit' | 'create'
    rowData: FuelItem | null
    replaceRowData?: ({ newData } : {newData: FuelItem}) => void
    reloadFuelData?: () => void
    toggleAddForm?: () => void
  }) => {
  const { t } = useTranslation()
  const {
    activeCompany, fuelData: fuelListingData,
  } = useContext(CarbonEnrollmentContext)
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const postUrl = action === 'edit' ? '/fuelDataUpdate' : '/fuelDataEntry'

  const existingInvoiceOptions: FieldSelectProps[] = fuelListingData.map((item) => ({
    label: item.invoiceNumber,
    id: item.invoiceId.toString(),
  }))

  const selectedInvoice = existingInvoiceOptions.find(
    (option: FieldSelectProps) => option.id === rowData?.invoiceId.toString(),
  ) || null

  const ValidationSchema = Yup.object().shape({
    invoice: Yup.mixed()
      .when('invoiceId', {
        is: (e) => e === undefined,
        then: Yup.mixed().required(t('fuelManagement.validationMessages.invoice'))
          .test('type', t('fuelManagement.validationMessages.invoiceFileType'), (value) => value && (
            value.type === 'image/jpeg'
            || value.type === 'image/png'
            || value.type === 'application/pdf'
            || value.type === 'application/xlx'
            || value.type === 'application/doc'
          )),
        otherwise: Yup.mixed().notRequired(),
      }),
    invoiceId: Yup.string().when('invoice', {
      is: (e) => e === undefined,
      then: Yup.string().required(t('fuelManagement.validationMessages.invoiceId')),
      otherwise: Yup.string().notRequired(),
    }),
    date: Yup.string().required(t('fuelManagement.validationMessages.date')),
    petrol: Yup.string().required(t('fuelManagement.validationMessages.petrol')),
    diesel: Yup.string().required(t('fuelManagement.validationMessages.diesel')),
    invoiceNumber: Yup.string().required(t('fuelManagement.validationMessages.invoiceNumber')),
  }, [['invoice', 'invoiceId']])

  const separatorStyles = {
    borderBottom: `1px dotted ${theme.colors.blueUI}`,
    paddingBottom: theme.space.md,
    marginBottom: theme.space.md,
  }

  const handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: any) => {
    if (e.target.files) {
      setFieldValue('invoice', e.target.files![0])
      setTimeout(() => { setFieldValue('invoiceId', '') }, 200)
    }
  }

  return (
    <Formik
      initialValues={{
        invoice: '',
        invoiceId: rowData?.invoiceId ?? '',
        date: rowData?.date ?? '',
        petrol: rowData?.petrol ?? '',
        diesel: rowData?.diesel ?? '',
        invoiceNumber: rowData?.invoiceNumber ?? '',
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values) => {
        setIsSaving(true)
        const formDataToSave = new FormData()
        const selectedDate = new Date(values.date)
        formDataToSave.append('date', formatDateStrings(selectedDate))
        formDataToSave.append('year', selectedDate.getFullYear().toString())
        formDataToSave.append('petrol', values.petrol.toString())
        formDataToSave.append('diesel', values.diesel.toString())
        formDataToSave.append('invoiceNumber', values.invoiceNumber)
        formDataToSave.append('companyId', activeCompany!.id.toString())
        formDataToSave.append('invoice', values.invoice)
        formDataToSave.append('invoiceId', values.invoiceId.toString())
        formDataToSave.append('id', rowData?.id.toString() ?? '')

        API.post(postUrl, formDataToSave, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then(
          ({ data }: {data: FuelItem}) => {
            if (reloadFuelData) {
              reloadFuelData()
            }
            if (toggleAddForm) {
              toggleAddForm()
            }
            if (replaceRowData) {
              replaceRowData({ newData: data })
            }
            setIsSaving(false)
          },
        ).catch(() => {
          setIsSaving(false)
        })
      }}
    >
      {({
        handleSubmit, errors, setFieldValue, values,
      }) => (
        <form
          onSubmit={handleSubmit}
          style={separatorStyles}
          id="fuelDataEntryForm"
        >
          <AddFormHeading>{rowData ? t('cropRotation.editRowTitle') : t('cropRotation.addRowTitle')}</AddFormHeading>
          <InvoiceChoiceContainer>
            <div className="uploadHolder">
              <Button
                fullWidth
                component="label"
                color="info"
                variant="contained"
                tabIndex={-1}
                startIcon={<FontAwesomeIcon icon={faUpload} />}
              >
                {t('fuelManagement.uploadInvoice')}
                <input type="file" className="visuallyHidden" onChange={(e) => handleUploadFile(e, setFieldValue)} />
              </Button>
              {errors.invoice && (<p className="errorMessages">{errors.invoice}</p>)}
            </div>
            <div className="separator">{t('fuelManagement.or')}</div>
            <Autocomplete
              disablePortal
              options={existingInvoiceOptions}
              defaultValue={selectedInvoice}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('fuelManagement.existingInvoice')}
                  error={!!errors.invoiceId}
                  helperText={errors.invoiceId ? errors.invoiceId : ''}
                />
              )}
              fullWidth
              onChange={(event, newValue: FieldSelectProps | null) => {
                setFieldValue('invoiceId', newValue?.id)
                setTimeout(() => { setFieldValue('invoice', '') }, 200)
              }}
            />
          </InvoiceChoiceContainer>
          <DataTableFormFieldsGrid>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={t('general.date')}
                inputFormat="dd/MM/yyyy"
                value={values.date}
                onChange={(newValue) => {
                  setFieldValue('date', newValue)
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    error={!!errors.date}
                    helperText={errors.date ? errors.date : ''}
                  />
                )}
              />
            </LocalizationProvider>
            <TextField
              label={t('fuelManagement.invoiceNumber')}
              name="invoiceNumber"
              value={values.invoiceNumber}
              onChange={(newValue) => {
                setFieldValue('invoiceNumber', newValue.target.value)
              }}
              fullWidth
              error={!!errors.invoiceNumber}
              helperText={errors.invoiceNumber ? errors.invoiceNumber : ''}
            />
            <TextField
              label={t('fuelManagement.petrol')}
              name="petrol"
              value={values.petrol}
              onChange={(newValue) => {
                setFieldValue('petrol', newValue.target.value)
              }}
              fullWidth
              error={!!errors.petrol}
              helperText={errors.petrol ? errors.petrol : ''}
            />
            <TextField
              label={t('fuelManagement.diesel')}
              name="diesel"
              value={values.diesel}
              onChange={(newValue) => {
                setFieldValue('diesel', newValue.target.value)
              }}
              fullWidth
              error={!!errors.diesel}
              helperText={errors.diesel ? errors.diesel : ''}
            />
          </DataTableFormFieldsGrid>

          <LoadingButton
            variant="contained"
            type="button"
            color="primary"
            loading={isSaving}
            loadingPosition="start"
            endIcon={<FontAwesomeIcon icon={faAnglesRight} />}
            onClick={() => handleSubmit()}
          >
            {t('general.save')}
          </LoadingButton>
        </form>
      )}
    </Formik>
  )
}
