export type OrderTypes = 'asc' | 'desc';

function descendingComparator<T>(a: T, b: T, orderByParam: keyof T) {
  if (b[orderByParam] < a[orderByParam]) {
    return -1
  }
  if (b[orderByParam] > a[orderByParam]) {
    return 1
  }
  return 0
}

export function getComparator<Key extends keyof any>(
  orderParam: OrderTypes,
  orderByParam: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
  return orderParam === 'desc'
    ? (a, b) => descendingComparator(a, b, orderByParam)
    : (a, b) => -descendingComparator(a, b, orderByParam)
}
