import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as ls from 'local-storage'
import { API } from '../../../providers/API'
import { FertilizerUsageItem, updateFertilizerUsageEntriesArgs } from '../FertilizerUsageManagement/FertilizerUsage.types'
import { CarbonEnrollmentContext } from '../../../providers/CarbonEnrollmentProvider'
import { Loading } from '../../Loading/Loading'
import { FertilizerItem as FertilizerInventoryItem } from '../InventoryOverlays/Fertilizers/Fertilizer.types'
import { FertilizerUsageDataRow } from '../FertilizerUsageManagement/FertilizerUsageDataRow'
import { InfoNotification } from '../../SuccessNotification/SuccessNotification.styles'

export const FertilizerUsageManagement = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [fertilizerUsageData, setFertilizerUsageData] = useState<FertilizerUsageItem[]>([])
  const [fertilizerInventory, setFertilizerInventory] = useState<FertilizerInventoryItem[]>([])
  const [isFetchingFertilizerInventory, setIsFetchingFertilizerInventory] = useState<boolean>(false)
  const { activeCompany } = useContext(CarbonEnrollmentContext)
  const { t } = useTranslation()

  const fetchFertilizerInventory = () => {
    setIsFetchingFertilizerInventory(true)

    API.get(`/fertilizerInventory/${activeCompany?.id}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then(({ data }: {data: FertilizerInventoryItem[]}) => {
      setFertilizerInventory(data)
      setIsFetchingFertilizerInventory(false)
    })
  }

  const updateFertilizerUsageEntries = (
    { cropId, year, data }: updateFertilizerUsageEntriesArgs,
  ) => {
    const newFertilizerUsageData = fertilizerUsageData.map((row: FertilizerUsageItem) => {
      if (row.cropId === cropId && row.year === year) {
        return data
      }
      return row
    })
    setFertilizerUsageData(newFertilizerUsageData)
  }

  useEffect(() => {
    setIsLoading(true)
    API.get(`/fertilizerUsageListing/${activeCompany?.id}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then(({ data }: {data: FertilizerUsageItem[]}) => {
      setFertilizerUsageData(data)
      setIsLoading(false)
    })

    fetchFertilizerInventory()
  }, [activeCompany])

  if (isLoading || !fertilizerInventory) {
    return <Loading />
  }

  return (
    <>
      {fertilizerUsageData && fertilizerUsageData.length > 0
        ? fertilizerUsageData.map((row: FertilizerUsageItem) => (
          <FertilizerUsageDataRow
            rowData={row}
            fertilizerInventory={fertilizerInventory}
            fetchFertilizerInventory={fetchFertilizerInventory}
            isFetchingFertilizerInventory={isFetchingFertilizerInventory}
            updateFertilizerUsageEntries={updateFertilizerUsageEntries}
            key={`row-key-${row.year}-${row.cropId}`}
          />
        )) : (
          <InfoNotification>{t('fertilizerUsage.noData')}</InfoNotification>
        )}
    </>
  )
}
