import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as ls from 'local-storage'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import type {} from '@mui/x-date-pickers/themeAugmentation'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight, faCancel } from '@fortawesome/free-solid-svg-icons'
import { CropRotationItem } from './CropRotation.types'
import { CashCropOptionsTypes } from '../../utils/useCashCropsData'
import { AddFormHeading, DataTableFormFieldsGrid } from './CropRotation.styles'
import { API } from '../../providers/API'
import { theme } from '../../theme/theme'
import { formatDateStrings } from '../../utils/formatDateStrings'
import { CarbonEnrollmentCropRotationItem } from '../CarbonEnrollment/CarbonEnrollment.types'
import { FieldItem } from '../FieldsListing/Fields.types'
import { GlobalStateContext } from '../../providers/GlobalStateProvider'
import { CarbonEnrollmentContext } from '../../providers/CarbonEnrollmentProvider'
import { Loading } from '../Loading/Loading'
import { useEnrollmentSeasonsData } from '../CarbonEnrollment/useEnrollmentSeasonsData'
import { useFieldTillageData } from './useFieldTillageData'
import { FailureNotification } from '../SuccessNotification/SuccessNotification.styles'

interface FieldSelectProps {
  label: string
  id: number
}

export const CropRotationEditForm = (
  {
    isEnrollment = false, rowData, cropType,
    cashCropOptions, replaceRowData, reloadCropRotationData, fieldId, userId, toggleAddForm,
  }: {
    isEnrollment?: boolean
    rowData: CropRotationItem | CarbonEnrollmentCropRotationItem | null
    cropType?: 'prevCrop' | 'nextCrop' | 'carbonEnrollment'
    cashCropOptions: CashCropOptionsTypes[]
    replaceRowData?: ({ newData, cropTypes } : {newData: CropRotationItem, cropTypes: 'nextCrop' | 'previousCrops' | 'carbonEnrollment'}) => void
    reloadCropRotationData?: () => void
    fieldId?: number
    userId?: number
    toggleAddForm?: () => void
  },
) => {
  const transformDateForJS = (dateString: string) => {
    const parts = dateString.split('-')
    return `${parseInt(parts[2])}-${parseInt(parts[1])}-${parseInt(parts[0])}`
  }

  const { t } = useTranslation()
  const {
    userInfo,
  } = useContext(GlobalStateContext)
  const {
    activeCompany,
  } = useContext(CarbonEnrollmentContext)
  const { seasonFormOptions, seasonRanges } = useEnrollmentSeasonsData()
  const { fieldTillageOptions } = useFieldTillageData()
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [fieldOptions, setFieldOptions] = useState<FieldSelectProps[]>([])
  const [errorSavingMessage, setErrorSavingMessage] = useState<string | null>(null)
  const [seedingDate, setSeedingDate] = React.useState<Date>(
    new Date(rowData?.seedingDate ? transformDateForJS(rowData?.seedingDate) : new Date()),
  )
  const [harvestDate, setHarvestDate] = React.useState<Date>(
    new Date(rowData?.harvestDate ? transformDateForJS(rowData?.harvestDate) : new Date()),
  )
  const carbonEnrollmentUrl = cropType === 'carbonEnrollment' ? 'carbonCropRotationUpdate' : '/carbonCropRotationAdd'
  const postUrl = isEnrollment ? carbonEnrollmentUrl : '/cropRotationAdd'

  useEffect(() => {
    API.get(`/fields/search/${userInfo.id}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
      const fieldOptionsArray:any = []
      response.data.forEach((item:FieldItem) => {
        fieldOptionsArray.push({ label: item.name, id: item.id })
      })

      setFieldOptions(fieldOptionsArray)
    })
  }, [])

  const initialCashCropOption = cashCropOptions.find(
    (option: CashCropOptionsTypes) => option.id === rowData?.cropId,
  ) || null

  const initialTillageOption = fieldTillageOptions.find(
    (option: CashCropOptionsTypes) => option.id === rowData?.tillage,
  ) || null

  const fieldIdValidation = isEnrollment ? Yup.number().required(t('cropRotation.validationMessages.fieldId')) : Yup.string().nullable().notRequired()
  const ValidationSchema = Yup.object().shape({
    fieldId: fieldIdValidation,
    cashCrop: Yup.number().required(t('cropRotation.validationMessages.cashCrop')),
    tillage: Yup.number().required(t('cropRotation.validationMessages.tillage')),
  })

  const handleSeasonSelect = (seasonId: number) => {
    const selectedSeason = seasonFormOptions.find((option) => option.id === seasonId)
    // @ts-ignore
    const seasonStartDate = new Date(`${selectedSeason?.year}-${seasonRanges[selectedSeason.season][0]}`)
    // @ts-ignore
    const seasonEndtDate = new Date(`${selectedSeason?.year}-${seasonRanges[selectedSeason.season][1]}`)

    const selectedStartDate = new Date(
      seasonStartDate.getTime() + Math.floor(Math.random() * 15) * 86400000,
    )

    const selectedEndDate = new Date(
      seasonEndtDate.getTime() - Math.floor(Math.random() * 15) * 86400000,
    )

    return {
      generatedSeedingDate: selectedStartDate,
      generatedHarvestDate: selectedEndDate,
    }
  }

  const separatorStyles = {
    borderBottom: `1px dotted ${theme.colors.blueUI}`,
    paddingBottom: theme.space.md,
    marginBottom: theme.space.md,
  }

  if (isEnrollment && fieldOptions.length === 0) {
    return <Loading />
  }

  return (
    <Formik
      initialValues={{
        fieldId: rowData?.fieldId ?? null,
        cashCrop: initialCashCropOption?.id ?? '',
        seedingDate,
        harvestDate,
        tillage: rowData?.tillage ?? '',
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values) => {
        setIsSaving(true)
        const getCropTypeToSave = () => {
          if (isEnrollment) {
            return 'prevCrop'
          }
          return rowData?.type ?? cropType ?? 'prevCrop'
        }

        const dataToSave = {
          ...rowData,
          userId: rowData?.userId ?? userId ?? userInfo.id,
          fieldId: rowData?.fieldId ?? fieldId ?? values.fieldId,
          cropId: values.cashCrop,
          seedingDate: formatDateStrings(values.seedingDate),
          harvestDate: formatDateStrings(values.harvestDate),
          type: getCropTypeToSave(),
          tillage: values.tillage,
          companyId: isEnrollment ? activeCompany?.id : null,
        }

        API.post(postUrl, dataToSave, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then(
          ({ data }: {data: CropRotationItem}) => {
            setIsSaving(false)
            if (data.status === 402 && data.message) {
              setErrorSavingMessage(data.message)
              return
            }
            setErrorSavingMessage(null)
            if (reloadCropRotationData) {
              reloadCropRotationData()
            }
            if (toggleAddForm) {
              toggleAddForm()
            }
            if (replaceRowData) {
              const cropTypeToReplace = cropType ?? rowData?.type === 'prevCrop' ? 'previousCrops' : 'nextCrop'
              replaceRowData({ newData: data, cropTypes: isEnrollment ? 'carbonEnrollment' : cropTypeToReplace })
            }
          },
        ).catch(() => {
          setIsSaving(false)
        })
      }}
    >
      {({
        handleSubmit, errors, setFieldValue, values,
      }) => (
        <form
          onSubmit={handleSubmit}
          style={isEnrollment ? separatorStyles : undefined}
        >
          <AddFormHeading>{rowData ? t('cropRotation.editRowTitle') : t('cropRotation.addRowTitle')}</AddFormHeading>
          <DataTableFormFieldsGrid>
            {isEnrollment && (
              <>
                <Autocomplete
                  disablePortal
                  options={fieldOptions}
                  value={values.fieldId ? fieldOptions.find(
                    (option) => option.id === values.fieldId,
                  ) : null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('general.field')}
                      error={!!errors.fieldId}
                      helperText={errors.fieldId ? errors.fieldId : ''}
                    />
                  )}
                  fullWidth
                  onChange={(event, newValue: FieldSelectProps | null) => {
                    setFieldValue('fieldId', newValue?.id)
                  }}
                />
                <Autocomplete
                  disablePortal
                  options={seasonFormOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('carbonEnrollment.period')}
                    />
                  )}
                  fullWidth
                  onChange={(event, newValue: FieldSelectProps | null) => {
                    if (newValue) {
                      const {
                        generatedSeedingDate, generatedHarvestDate,
                      } = handleSeasonSelect(newValue?.id)
                      setFieldValue('seedingDate', generatedSeedingDate)
                      setFieldValue('harvestDate', generatedHarvestDate)
                      setSeedingDate(generatedSeedingDate)
                      setHarvestDate(generatedHarvestDate)
                    }
                  }}
                />
              </>
            )}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={t('cropRotation.seedingDate')}
                inputFormat="dd/MM/yyyy"
                value={values.seedingDate}
                onChange={(newValue) => {
                  setSeedingDate(newValue!)
                  setFieldValue('seedingDate', newValue)
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                  />
                )}
              />
              <DatePicker
                label={t('cropRotation.harvestDate')}
                inputFormat="dd/MM/yyyy"
                value={values.harvestDate}
                onChange={(newValue) => {
                  setHarvestDate(newValue!)
                  setFieldValue('harvestDate', newValue)
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                  />
                )}
              />

            </LocalizationProvider>
            <Autocomplete
              disablePortal
              options={cashCropOptions}
              defaultValue={initialCashCropOption}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="cashCrop"
                  label={t('cropRotation.cropName')}
                  error={!!errors.cashCrop}
                  helperText={errors.cashCrop ? errors.cashCrop : ''}
                />
              )}
              fullWidth
              onChange={(event, newValue: CashCropOptionsTypes | null) => {
                setFieldValue('cashCrop', newValue?.id)
              }}
            />
            <Autocomplete
              disablePortal
              options={fieldTillageOptions}
              defaultValue={initialTillageOption}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="tillage"
                  label={t('cropRotation.tillage')}
                  error={!!errors.tillage}
                  helperText={errors.tillage ? errors.tillage : ''}
                />
              )}
              fullWidth
              onChange={(event, newValue: CashCropOptionsTypes | null) => {
                if (newValue) {
                  setFieldValue('tillage', newValue.id)
                }
              }}
            />
          </DataTableFormFieldsGrid>

          {errorSavingMessage && (
            <FailureNotification>{t(`cropRotation.${errorSavingMessage}`)}</FailureNotification>
          )}
          <LoadingButton
            variant="contained"
            type="button"
            color="primary"
            loading={isSaving}
            loadingPosition="start"
            endIcon={<FontAwesomeIcon icon={faAnglesRight} />}
            onClick={() => handleSubmit()}
          >
            {t('general.save')}
          </LoadingButton>
          {!isEnrollment && (
          <Button
            variant="contained"
            type="button"
            color="error"
            onClick={() => {
              if (toggleAddForm) {
                toggleAddForm()
              }
            }}
            endIcon={<FontAwesomeIcon icon={faCancel} />}
            style={{ marginLeft: theme.space.sm }}
          >
            {t('general.cancel')}
          </Button>
          )}
        </form>
      )}
    </Formik>
  )
}
