import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as ls from 'local-storage'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import type {} from '@mui/x-date-pickers/themeAugmentation'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight, faUpload } from '@fortawesome/free-solid-svg-icons'
import { AddFormHeading, DataTableFormFieldsGrid } from '../../CropRotation/CropRotation.styles'
import { API } from '../../../providers/API'
import { theme } from '../../../theme/theme'
import { CarbonEnrollmentContext } from '../../../providers/CarbonEnrollmentProvider'
import { FertilizerItem } from './FertilizerInvoiceManagement.types'
import { formatDateStrings } from '../../../utils/formatDateStrings'
import { InvoiceChoiceContainer } from './FertilizerInvoiceManagement.styles'

interface FieldSelectProps {
  label: string
  id: string
}

const fertilizerTypeOptions: FieldSelectProps[] = [
  { label: 'N', id: 'N' },
  { label: 'NP', id: 'NP' },
  { label: 'NPK', id: 'NPK' },
]

export const FertilizerDataEntryForm = ({
  action, rowData, replaceRowData, reloadData, toggleAddForm,
}: {
    action: 'edit' | 'create'
    rowData: FertilizerItem | null
    replaceRowData?: ({ newData } : {newData: FertilizerItem}) => void
    reloadData?: () => void
    toggleAddForm?: () => void
  }) => {
  const { t } = useTranslation()
  const {
    activeCompany, fertilizerData: fertilizerListingData,
  } = useContext(CarbonEnrollmentContext)
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const postUrl = action === 'edit' ? '/fertilizerDataUpdate' : '/fertilizerDataEntry'

  const existingInvoiceOptions: FieldSelectProps[] = fertilizerListingData.map((item) => ({
    label: item.invoiceNumber,
    id: item.invoiceId.toString(),
  }))

  const selectedInvoice = existingInvoiceOptions.find(
    (option: FieldSelectProps) => option.id === rowData?.invoiceId.toString(),
  ) || null

  const selectedFertilizerType = fertilizerTypeOptions.find(
    (option: FieldSelectProps) => option.id === rowData?.type,
  ) || null

  const ValidationSchema = Yup.object().shape({
    invoice: Yup.mixed()
      .when('invoiceId', {
        is: (e) => e === undefined,
        then: Yup.mixed().required(t('fertilizerInvoiceManagement.validationMessages.invoice'))
          .test('type', t('fertilizerInvoiceManagement.validationMessages.invoiceFileType'), (value) => value && (
            value.type === 'image/jpeg'
            || value.type === 'image/png'
            || value.type === 'application/pdf'
            || value.type === 'application/xlx'
            || value.type === 'application/doc'
          )),
        otherwise: Yup.mixed().notRequired(),
      }),
    invoiceId: Yup.string().when('invoice', {
      is: (e) => e === undefined,
      then: Yup.string().required(t('fertilizerInvoiceManagement.validationMessages.invoiceId')),
      otherwise: Yup.string().notRequired(),
    }),
    date: Yup.string().required(t('fertilizerInvoiceManagement.validationMessages.date')),
    type: Yup.string().required(t('fertilizerInvoiceManagement.validationMessages.type')),
    fertName: Yup.string().required(t('fertilizerInvoiceManagement.validationMessages.fertName')),
    N: Yup.string().required(t('fertilizerInvoiceManagement.validationMessages.N')),
    P: Yup.string().when('type', {
      is: (e) => e === 'NP' || e === 'NPK',
      then: Yup.string().required(t('fertilizerInvoiceManagement.validationMessages.P')),
      otherwise: Yup.string().notRequired(),
    }),
    K: Yup.string().when('type', {
      is: (e) => e === 'NPK',
      then: Yup.string().required(t('fertilizerInvoiceManagement.validationMessages.K')),
      otherwise: Yup.string().notRequired(),
    }),
    quantity: Yup.string().required(t('fertilizerInvoiceManagement.validationMessages.quantity')),
    invoiceNumber: Yup.string().required(t('fertilizerInvoiceManagement.validationMessages.invoiceNumber')),
  }, [['invoice', 'invoiceId']])

  const separatorStyles = {
    borderBottom: `1px dotted ${theme.colors.blueUI}`,
    paddingBottom: theme.space.md,
    marginBottom: theme.space.md,
  }

  const handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: any) => {
    if (e.target.files) {
      setFieldValue('invoice', e.target.files![0])
      setTimeout(() => { setFieldValue('invoiceId', '') }, 200)
    }
  }

  return (
    <Formik
      initialValues={{
        invoice: '',
        invoiceId: rowData?.invoiceId ?? '',
        date: rowData?.date ?? '',
        type: rowData?.type ?? '',
        fertName: rowData?.fertName ?? '',
        N: rowData?.N ?? '',
        P: rowData?.P ?? '',
        K: rowData?.K ?? '',
        quantity: rowData?.quantity ?? '',
        invoiceNumber: rowData?.invoiceNumber ?? '',
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values) => {
        setIsSaving(true)
        const formDataToSave = new FormData()
        const selectedDate = new Date(values.date)
        formDataToSave.append('date', formatDateStrings(selectedDate))
        formDataToSave.append('year', selectedDate.getFullYear().toString())
        formDataToSave.append('type', values.type)
        formDataToSave.append('fertName', values.fertName)
        formDataToSave.append('N', values.N.toString())
        formDataToSave.append('P', values.P.toString())
        formDataToSave.append('K', values.K.toString())
        formDataToSave.append('quantity', values.quantity.toString())
        formDataToSave.append('invoiceNumber', values.invoiceNumber)
        formDataToSave.append('companyId', activeCompany!.id.toString())
        formDataToSave.append('invoice', values.invoice)
        formDataToSave.append('invoiceId', values.invoiceId.toString())
        formDataToSave.append('id', rowData?.id.toString() ?? '')

        API.post(postUrl, formDataToSave, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then(
          ({ data }: {data: FertilizerItem}) => {
            if (reloadData) {
              reloadData()
            }
            if (toggleAddForm) {
              toggleAddForm()
            }
            if (replaceRowData) {
              replaceRowData({ newData: data })
            }
            setIsSaving(false)
          },
        ).catch(() => {
          setIsSaving(false)
        })
      }}
    >
      {({
        handleSubmit, errors, setFieldValue, values,
      }) => (
        <form
          onSubmit={handleSubmit}
          style={separatorStyles}
        >
          <AddFormHeading>{rowData ? t('cropRotation.editRowTitle') : t('cropRotation.addRowTitle')}</AddFormHeading>
          <InvoiceChoiceContainer>
            <div className="uploadHolder">
              <Button
                fullWidth
                component="label"
                color="info"
                variant="contained"
                tabIndex={-1}
                startIcon={<FontAwesomeIcon icon={faUpload} />}
              >
                {t('fertilizerInvoiceManagement.uploadInvoice')}
                <input type="file" className="visuallyHidden" onChange={(e) => handleUploadFile(e, setFieldValue)} />
              </Button>
              {errors.invoice && (<p className="errorMessages">{errors.invoice}</p>)}
            </div>
            <div className="separator">{t('fertilizerInvoiceManagement.or')}</div>
            <Autocomplete
              disablePortal
              options={existingInvoiceOptions}
              defaultValue={selectedInvoice}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('fertilizerInvoiceManagement.existingInvoice')}
                  error={!!errors.invoiceId}
                  helperText={errors.invoiceId ? errors.invoiceId : ''}
                />
              )}
              fullWidth
              onChange={(event, newValue: FieldSelectProps | null) => {
                setFieldValue('invoiceId', newValue?.id)
                setTimeout(() => { setFieldValue('invoice', '') }, 200)
              }}
            />
          </InvoiceChoiceContainer>
          <DataTableFormFieldsGrid>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={t('general.date')}
                inputFormat="dd/MM/yyyy"
                value={values.date}
                onChange={(newValue) => {
                  setFieldValue('date', newValue)
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    error={!!errors.date}
                    helperText={errors.date ? errors.date : ''}
                  />
                )}
              />
            </LocalizationProvider>
            <TextField
              label={t('fertilizerInvoiceManagement.invoiceNumber')}
              name="invoiceNumber"
              value={values.invoiceNumber}
              onChange={(newValue) => {
                setFieldValue('invoiceNumber', newValue.target.value)
              }}
              fullWidth
              error={!!errors.invoiceNumber}
              helperText={errors.invoiceNumber ? errors.invoiceNumber : ''}
            />
            <Autocomplete
              disablePortal
              options={fertilizerTypeOptions}
              defaultValue={selectedFertilizerType}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('fertilizerInvoiceManagement.fertilizerType')}
                  error={!!errors.type}
                  helperText={errors.type ? errors.type : ''}
                />
              )}
              fullWidth
              onChange={(event, newValue: FieldSelectProps | null) => {
                setFieldValue('type', newValue?.id)
              }}
            />
            <TextField
              label={t('fertilizerInvoiceManagement.N')}
              name="N"
              value={values.N}
              onChange={(newValue) => {
                setFieldValue('N', newValue.target.value)
              }}
              fullWidth
              error={!!errors.N}
              helperText={errors.N ? errors.N : ''}
            />
            {(values.type === 'NP' || values.type === 'NPK') && (
            <TextField
              label={t('fertilizerInvoiceManagement.P')}
              name="P"
              value={values.P}
              onChange={(newValue) => {
                setFieldValue('P', newValue.target.value)
              }}
              fullWidth
              error={!!errors.P}
              helperText={errors.P ? errors.P : ''}
            />
            )}
            {values.type === 'NPK' && (
            <TextField
              label={t('fertilizerInvoiceManagement.K')}
              name="K"
              value={values.K}
              onChange={(newValue) => {
                setFieldValue('K', newValue.target.value)
              }}
              fullWidth
              error={!!errors.K}
              helperText={errors.K ? errors.K : ''}
            />
            )}
            <TextField
              label={t('fertilizerInvoiceManagement.quantity')}
              name="quantity"
              value={values.quantity}
              onChange={(newValue) => {
                setFieldValue('quantity', newValue.target.value)
              }}
              fullWidth
              error={!!errors.quantity}
              helperText={errors.quantity ? errors.quantity : ''}
            />
            <TextField
              label={t('fertilizerInvoiceManagement.fertName')}
              name="fertName"
              value={values.fertName}
              onChange={(newValue) => {
                setFieldValue('fertName', newValue.target.value)
              }}
              fullWidth
              error={!!errors.fertName}
              helperText={errors.fertName ? errors.fertName : ''}
            />
          </DataTableFormFieldsGrid>

          <LoadingButton
            variant="contained"
            type="button"
            color="primary"
            loading={isSaving}
            loadingPosition="start"
            endIcon={<FontAwesomeIcon icon={faAnglesRight} />}
            onClick={() => handleSubmit()}
          >
            {t('general.save')}
          </LoadingButton>
        </form>
      )}
    </Formik>
  )
}
