import styled, { css } from 'styled-components'
import { theme } from '../../../theme/theme'

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    gap: ${theme.space.md};
    padding: ${theme.space.md} ${theme.space.lg};
    
    ${theme.mediaQueries.mdMax} {
        flex-direction: column;
    }
`

export const CollapsibleContainer = styled.div<{isOpen: boolean}>(
  ({ isOpen }) => css`
    padding: ${theme.space.md} ${theme.space.lg};
    display: ${isOpen ? 'block' : 'none'};
`,
)

export const OpenFieldsWithCropButton = styled.button`
    background: ${theme.colors.agroOrange};
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    padding: ${theme.space.xs} ${theme.space.sm};
`

export const CollapsibleCOntainerButton = styled.button`
    position: relative;
    top: 4px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    color: ${theme.colors.blueBGUIMedium};
    padding: 0;
    margin-left: ${theme.space.md};
`

export const FertilizerInputsRow = styled.div`
    display: flex;
    justify-content: space-between;
    gap: ${theme.space.md};
    margin-bottom: ${theme.space.md};
`

export const FakeLoadingInput = styled.div`
    width: 100%;
    padding-bottom: ${theme.space.md};

    &::before {
    display: block;
        content: '';
        width: 100%;
        height: 100%;
        border: ${theme.colors.blueUI} 3px solid;
        border-radius: 5px;
    }
`
