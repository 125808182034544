import React, {
  useContext, useEffect, useRef, useState,
} from 'react'
import * as ls from 'local-storage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGasPump, faWarehouse, faWarning } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { CarbonEnrollmentContext } from '../../../providers/CarbonEnrollmentProvider'
import { OverlayContainer, SimpleBox } from '../../../App.styles'
import { FuelInventoryListing } from './Fuel/FuelInventoryListing'
import { FertilizersInventoryListing } from './Fertilizers/FertilizersInventoryListing'
import { InventoryButton, InventoryButtonsContainer } from '../CarbonEnrollment.styles'
import { API } from '../../../providers/API'
import { FuelInventoryItem } from './Fuel/Fuel.types'

export const InventoryOverlaysContainer = () => {
  const {
    activeCompany,
    fuelData,
    fuelInventoryData,
    setFuelInventoryData,
  } = useContext(CarbonEnrollmentContext)
  const [isAllFuelDataConfirmed, setIsAllFuelDataConfirmed] = useState<boolean>(true)
  const [showFuelInventory, setShowFuelInventory] = useState(false)
  const [showFertilizerInventory, setShowFertilizerInventory] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    API.get(`/fuelInventory/${activeCompany?.id}`, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then(({ data }: {data: FuelInventoryItem[]}) => {
      setFuelInventoryData(data)
    })
  }, [fuelData, activeCompany])

  useEffect(() => {
    const unconfirmedFuelEntries = fuelInventoryData.filter(
      (entry: FuelInventoryItem) => entry.confirmation === 0,
    )
    setIsAllFuelDataConfirmed(unconfirmedFuelEntries.length > 0)
  }, [fuelInventoryData])

  const fuelInventoryContentRef = useRef<HTMLDivElement | null>(null)
  const fertilizerInventoryContentRef = useRef<HTMLDivElement | null>(null)

  const handleFuelInventoryDisplay = ({ event, overlay }: {event: any, overlay: boolean}) => {
    if (overlay && (
      !fuelInventoryContentRef.current || fuelInventoryContentRef.current.contains(event.target))) {
      return
    }

    setShowFuelInventory((prev: boolean) => !prev)
  }

  const handleFertilizerInventoryDisplay = ({ event, overlay }: {event: any, overlay: boolean}) => {
    if (overlay && (
      !fertilizerInventoryContentRef.current
       || fertilizerInventoryContentRef.current.contains(event.target))) {
      return
    }

    setShowFertilizerInventory((prev: boolean) => !prev)
  }

  const listenForEscapeKey = (e: any) => {
    if (e.key === 'Escape') {
      setShowFuelInventory(false)
      setShowFertilizerInventory(false)
    }
  }

  useEffect(() => {
    if (showFuelInventory || showFertilizerInventory) {
      window.addEventListener('keyup', listenForEscapeKey)
    }

    return () => window.removeEventListener('keyup', listenForEscapeKey)
  }, [showFuelInventory, showFertilizerInventory])

  return (
    <>
      <InventoryButtonsContainer>
        <InventoryButton onClick={
          (event: any) => handleFuelInventoryDisplay({ event, overlay: false })
}
        >
          <FontAwesomeIcon icon={faGasPump} size="2x" />
          <span>{t('carbonEnrollment.inventory.fuel')} {isAllFuelDataConfirmed && (<FontAwesomeIcon icon={faWarning} />)}</span>
        </InventoryButton>
        <InventoryButton onClick={(event: any) => handleFertilizerInventoryDisplay({ event, overlay: false })}><FontAwesomeIcon icon={faWarehouse} size="2x" />{t('carbonEnrollment.inventory.fertilizers')}</InventoryButton>
      </InventoryButtonsContainer>
      {showFuelInventory && (
      <OverlayContainer onClick={
        (event: any) => handleFuelInventoryDisplay({ event, overlay: true })
}
      >
        <SimpleBox inModal ref={fuelInventoryContentRef}>
          <FuelInventoryListing />
        </SimpleBox>
      </OverlayContainer>
      )}
      {showFertilizerInventory && (
      <OverlayContainer onClick={
        (event: any) => handleFertilizerInventoryDisplay({ event, overlay: true })
}
      >
        <SimpleBox inModal ref={fertilizerInventoryContentRef}>
          <FertilizersInventoryListing />
        </SimpleBox>
      </OverlayContainer>
      )}
    </>
  )
}
