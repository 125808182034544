import React, { useContext, Dispatch, SetStateAction } from 'react'
import Checkbox from '@mui/material/Checkbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaintBrush, faExpand, faCompress } from '@fortawesome/free-solid-svg-icons'
import { ControlsContainer, ToggleExpandedMapButton } from './FieldMap.styles'
import { theme } from '../../theme/theme'
import { MapDataContext } from '../../providers/MapDataProvider'

export const ToggleFieldFill = (
  {
    withZones, isMapExpanded, setIsMapExpanded, mapReference,
  }:
   {withZones?: boolean,
    isMapExpanded: boolean,
     setIsMapExpanded: Dispatch<SetStateAction<boolean>>,
     mapReference: any },
) => {
  const {
    fieldFill,
    setFieldFill,
    zoneVisibility,
    setZoneVisibility,
    nonZonedFieldsVisibility,
    setNonZonedFieldsVisibility,
  } = useContext(MapDataContext)

  const toggleExpandMap = () => () => {
    const mapObject = mapReference.current?.state.map
    setIsMapExpanded((prevState) => !prevState)
    // eslint-disable-next-line no-underscore-dangle
    const mapContainer = mapObject._container
    const mapOffset = mapContainer.getBoundingClientRect()

    setTimeout(() => {
      mapObject.resize()
      window.scrollTo({ top: isMapExpanded ? 0 : mapOffset.top - 5, left: 0, behavior: 'smooth' })
    }, 100)
  }

  return (
    <ControlsContainer>
      <ToggleExpandedMapButton type="button" onClick={toggleExpandMap()}>
        <FontAwesomeIcon icon={isMapExpanded ? faCompress : faExpand} color="#06C0E2" />
      </ToggleExpandedMapButton>
      <div>
        <Checkbox
          icon={<FontAwesomeIcon icon={faPaintBrush} color="#06C0E2" />}
          checkedIcon={<FontAwesomeIcon icon={faPaintBrush} color="#FFF" />}
          style={{
            borderRadius: '5px',
            padding: '5px',
            backgroundColor: fieldFill ? '#06C0E2' : '#FFF',
          }}
          size="small"
          checked={fieldFill}
          onChange={() => setFieldFill((value) => !value)}
        />
      </div>
      {withZones && setNonZonedFieldsVisibility && (
      <div>

        <Checkbox
          size="small"
          sx={{
            color: '#838383',
            '&.Mui-checked': {
              color: '#838383',
            },
            '& .MuiSvgIcon-root': {
              color: 'inherit',
            },
          }}
          style={{
            backgroundColor: '#FF0',
            borderRadius: '5px',
            padding: theme.space.xxs,
          }}
          checked={nonZonedFieldsVisibility}
          onChange={() => {
            setNonZonedFieldsVisibility((prevState: boolean) => !prevState)
          }}
        />

      </div>
      )}
      {withZones && setZoneVisibility && zoneVisibility?.map((item: any, index: number) => (
        <div key={item.id}>
          <Checkbox
            size="small"
            style={{
              backgroundColor: item.colour,
              borderRadius: '5px',
              padding: theme.space.xxs,
            }}
            checked={item.visible}
            onChange={() => {
              setZoneVisibility((prevState: any) => {
                const updatedVisibility = [...prevState]
                updatedVisibility[index].visible = !updatedVisibility[index].visible
                return updatedVisibility
              })
            }}
          />
        </div>
      ))}

    </ControlsContainer>
  )
}
