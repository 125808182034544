import React, {
  Dispatch, SetStateAction, useContext, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import * as ls from 'local-storage'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import LoadingButton from '@mui/lab/LoadingButton'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Button } from '@mui/material'
import { API } from '../../../providers/API'
import { CarbonEnrollmentContext } from '../../../providers/CarbonEnrollmentProvider'

import { InputField } from '../../FormElements/InputField'
import { FailureNotification, SuccessNotification } from '../../SuccessNotification/SuccessNotification.styles'
import { CompanyFormSeparator } from '../CarbonEnrollment.styles'

interface CompanySelectProps {
  label: string
  id: number
}

export const CompanyInfoForm = (
  { setShowCompanyPopup }: {setShowCompanyPopup?: Dispatch<SetStateAction<boolean>>},
) => {
  const {
    registeredCompanies,
    setRegisteredCompanies,
    activeCompany,
    setActiveCompany,
  } = useContext(CarbonEnrollmentContext)
  const [isLoading, setIsLoading] = useState(false)
  const [registerErrors, setRegisterErrors] = useState<string[]>([])
  const [formSuccess, setFormSuccess] = useState(false)
  const { t } = useTranslation()

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required(t('carbonEnrollment.companyForm.nameError')),
    VIES: Yup.string().required(t('carbonEnrollment.companyForm.viesError')),
    address: Yup.string().required(t('carbonEnrollment.companyForm.addressError')),
    city: Yup.string().required(t('carbonEnrollment.companyForm.cityError')),
    country: Yup.string().required(t('carbonEnrollment.companyForm.countryError')),
    manager: Yup.string().required(t('carbonEnrollment.companyForm.managerError')),
  })

  const companyOptions = registeredCompanies.map((company) => ({
    id: company.id,
    label: company.name,
  }))

  const handleChangeCompany = (newValue: CompanySelectProps | null) => {
    if (setShowCompanyPopup) {
      setShowCompanyPopup(false)
    }
    setActiveCompany(registeredCompanies.find((company) => company.id === newValue?.id))
  }

  const selectedCompanyOption = companyOptions.find((company) => company.id === activeCompany?.id)

  return (
    <>
      <Autocomplete
        disablePortal
        options={companyOptions}
        defaultValue={selectedCompanyOption}
        renderInput={(params) => <TextField {...params} label={t('carbonEnrollment.companyChoice')} />}
        fullWidth
        onChange={(event, newValue: CompanySelectProps | null) => {
          handleChangeCompany(newValue)
        }}
      />
      <CompanyFormSeparator>{t('carbonEnrollment.companyForm.orCreateANewCompany')}</CompanyFormSeparator>
      <Formik
        initialValues={{
          name: '',
          VIES: '',
          address: '',
          city: '',
          country: '',
          manager: '',
        }}
        validationSchema={ValidationSchema}
        enableReinitialize
        onSubmit={(values) => {
          setIsLoading(true)
          API.post('/createCompany', values, { headers: { Authorization: `Bearer ${ls.get('loginCredentials')}` } }).then((response) => {
            setRegisteredCompanies((prev) => [...prev, response.data])
            setActiveCompany(response.data)
            setFormSuccess(true)
            setIsLoading(false)
          }).catch((error) => {
            setIsLoading(false)
            if (error.response.data.errors) {
              const errorsArray = Object.keys(error.response.data.errors)
              const errors:any = errorsArray.map(
                () => error.response.data.errors[errorsArray[0]],
              )
              setRegisterErrors(errors)
            } else if (error.response.data.message) {
              setRegisterErrors([error.response.data.message])
            } else {
              setRegisterErrors([t('submitErrors.generic')])
            }
            const errorsArray = Object.keys(error.response.data.errors)
            const errors:any = errorsArray.map(
              () => error.response.data.errors[errorsArray[0]],
            )
            setRegisterErrors(errors)
          })
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <InputField label={t('carbonEnrollment.companyForm.name')} name="name" id="name" type="text" />
            <InputField label={t('carbonEnrollment.companyForm.vies')} name="VIES" id="VIES" type="text" />
            <InputField label={t('carbonEnrollment.companyForm.address')} name="address" id="address" type="text" />
            <InputField label={t('carbonEnrollment.companyForm.city')} name="city" id="city" type="text" />
            <InputField label={t('carbonEnrollment.companyForm.country')} name="country" id="country" type="text" />
            <InputField label={t('carbonEnrollment.companyForm.manager')} name="manager" id="manager" type="text" />

            <LoadingButton
              variant="contained"
              type="submit"
              color="primary"
              fullWidth
              loading={isLoading}
              loadingPosition="start"
            >
              {t('carbonEnrollment.companyForm.submit')}
            </LoadingButton>
            {formSuccess && (
              <>
                <SuccessNotification>{t('carbonEnrollment.companyForm.success')}</SuccessNotification>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setFormSuccess(false)
                    setRegisterErrors([])
                    if (setShowCompanyPopup) {
                      setShowCompanyPopup(false)
                    }
                  }}
                >{t('general.close')}
                </Button>
              </>
            )}
            {registerErrors?.map((item: any, index: number) => (
              <FailureNotification key={`error_${index}`}>{item}</FailureNotification>
            ))}
          </form>
        )}
      </Formik>
    </>
  )
}
