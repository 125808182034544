import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWarning } from '@fortawesome/free-solid-svg-icons'
import { FertilizerUsageItemField } from './FertilizerUsage.types'
import { OverlayContainer, SimpleBox } from '../../../App.styles'
import { OpenFieldsWithCropButton } from './FertilizerUsage.styles'

export const FieldsWithCropOverlay = ({
  fieldsData, cropName,
} : {
  fieldsData: FertilizerUsageItemField[], cropName: string
    }) => {
  const [showCropFields, setShowCropFields] = useState(false)
  const fieldsWithCropContentRef = useRef<HTMLDivElement | null>(null)
  const { t } = useTranslation()

  const handleFieldsWithCropInventoryDisplay = (
    { event, overlay }: {event: any, overlay: boolean},
  ) => {
    if (overlay && (
      !fieldsWithCropContentRef.current
      || fieldsWithCropContentRef.current.contains(event.target))) {
      return
    }

    setShowCropFields((prev: boolean) => !prev)
  }

  const listenForEscapeKey = (e: any) => {
    if (e.key === 'Escape') {
      setShowCropFields(false)
    }
  }

  useEffect(() => {
    if (showCropFields) {
      window.addEventListener('keyup', listenForEscapeKey)
    }

    return () => window.removeEventListener('keyup', listenForEscapeKey)
  }, [showCropFields])

  return (
    <>
      <OpenFieldsWithCropButton
        type="button"
        onClick={
        (event: any) => handleFieldsWithCropInventoryDisplay({ event, overlay: false })
}
      >
        <FontAwesomeIcon icon={faWarning} /> {t('fertilizerUsage.fieldsWith')} {t(`crops.${cropName}`)}
      </OpenFieldsWithCropButton>
      {showCropFields && (
      <OverlayContainer onClick={
        (event: any) => handleFieldsWithCropInventoryDisplay({ event, overlay: true })
}
      >
        <SimpleBox ref={fieldsWithCropContentRef}>
          {fieldsData.map((field) => (
            <div key={field.id}>
              <h4>{field.name}</h4>
            </div>
          ))}
        </SimpleBox>
      </OverlayContainer>
      )}
    </>
  )
}
